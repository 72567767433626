import axios from 'axios';
import { getReq, postReq, putReq } from './api';

const Url_path = process.env.REACT_APP_URL

class PerformanceManagement {
    getEmployees() {
        return getReq(`${Url_path}totalEmployees`)
        // return axios.get(`${Url_path}totalEmployees`);
    }

    getAllAppraisalConfig() {
        return getReq(`${Url_path}appraisalsConfig/getallAppraisals`)
        // return axios.get(`${Url_path}appraisalsConfig/getallAppraisals`);
    }
    

    getAllSelfAssessmentData(id) {
        return getReq(`${Url_path}api/sa/get-all-rows/${id}`)
        // return axios.get(`${Url_path}api/sa/get-all-rows/${id}`);
    }
    getAllQuestions(id)
    {   
        return getReq(`${Url_path}api/questions/getlistofQns/${id}`);
        // return axios.get(`${Url_path}api/questions/getlistofQns/${id}`);
    }

    getMasterIdSelfAssessmentData(eid) {
        return getReq(`${Url_path}api/sa/am-status/${eid}`)
        // return axios.get(`${Url_path}api/sa/am-status/${eid}`);
    }

    getStatusSelfAssessmentData(mid, formStatus) {
        return putReq(`${Url_path}api/sa/status/${mid}/${formStatus}`)
        // return axios.put(`${Url_path}api/sa/status/${mid}/${formStatus}`);
    }

    // getCreateFormSelfAssessmentData(master-id) {
    //     return axios.put(`${Url_path}api/sa/initialize/${master-id}`);
    // }

    addParameters(body) {
        //console.log(body);
        return postReq(`${Url_path}api/parameters/add`, body)
        // return axios.post(`${Url_path}api/parameters/add`, body);
    }

    getParameters() {
        return getReq(`${Url_path}api/parameters/get`)
    // return axios.get(`${Url_path}api/parameters/get`);
    }

    addQuestions(body) {
    //console.log(body);
    return postReq(`${Url_path}api/questions/add`, body)
    // return axios.post(`${Url_path}api/questions/add`, body);
    }

    getQuestions() {
        return getReq(`${Url_path}api/questions/get`)
    // return axios.get(`${Url_path}api/questions/get`);
    }

    addSkills(body) {
    //console.log(body);
    return postReq(`${Url_path}api/skills/add`, body)
    // return axios.post(`${Url_path}api/skills/add`, body);
    }

    getSkills() {
        return getReq(`${Url_path}api/skills/get`)
    // return axios.get(`${Url_path}api/skills/get`);
    }

    addRating(body) {
    //console.log(body);
    return postReq(`${Url_path}api/rating/add`, body)
    // return axios.post(`${Url_path}api/rating/add`, body);
    }
    
    getRating() {
        return getReq(`${Url_path}api/rating/get`)
    // return axios.get(`${Url_path}api/rating/get`);
    }
}

export default new PerformanceManagement();