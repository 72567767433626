import React, { useState } from "react";
import {
	Row,
	Col,
	Pagination,
	PaginationItem,
	PaginationLink,
	Table,
	Button
} from "reactstrap";
import "../CSS/MyTeam.css";

const Tables = (props) => {
	const { headers, body, handleViewClick, handleEDClick, defaultMessage } = props;
	const [currentpage, setCurrentpage] = useState(0);
	// const [lastpage, setLastpage] = useState(Math.ceil(props?.body?.length/10)-1);
	const entriesperpage = 10
	const last=Math.ceil(props?.body?.length/10)-1
	return (
		<>
			<Row className="mt-4 justify-content-between">
				{body.length >= 0 ? (
					<Table striped hover responsive className="team_table">
						<thead>
							<tr>
								{headers?.length > 0 && headers.map((header, index) => {
									return <th key={index}>{header}</th>;
								})}
							</tr>
						</thead>
						<tbody>

							{body?.length >= 0 && body
								.slice(
									currentpage * entriesperpage,
									(currentpage + 1) * entriesperpage
								)
								.map((row, rowIndex) => (
									<tr key={rowIndex}>
										{Object.values(row)?.map((col, colIndex) => (
											<td key={colIndex}>{col}</td>
										))}
										{handleViewClick && (
											<td>
												<Button style={{ marginBlock: "-10px", padding: "0px", textDecorationLine: "underline", textDecorationColor: "blue" }} color="none" onClick={() => handleViewClick(row)}>View</Button>
											</td>
										)}
										{handleEDClick && (
											<td>
												<Button
													style={{ marginLeft: "-10px", padding: "0px" }}
													color="none"
													onClick={() => handleEDClick(rowIndex, true)}>
													<i className="bi bi-pencil"></i>
												</Button>
												<Button color="none" onClick={() => handleEDClick(rowIndex, false)}>
													<i className="bi bi-trash"></i>
												</Button>
											</td>
										)}
									</tr>
								))
							}
						</tbody>
					</Table>

				) : (
					<>
						<Table className="mt-4">
							<tbody>
								<tr>
									<td colSpan="7" style={{ textAlign: "center" }}>
										<div
											style={{
												padding: "20px",
												backgroundColor: "#f2f2f2",
												borderRadius: "5px",
											}}
										>
											<h4>{defaultMessage? defaultMessage : "No data found"}</h4>
										</div>
									</td>
								</tr>
							</tbody>
						</Table>
					</>
				)}
			</Row >
			<Row>
				<Col>
					<Pagination style={{ justifyContent: "space-between" }}>
						<PaginationItem disabled={currentpage === 0}>
							<PaginationLink
								previous
								onClick={() => setCurrentpage((prev) => prev - 1)}
							>
								Previous
							</PaginationLink>
						</PaginationItem>
						<PaginationItem disabled={currentpage === last}>
							<PaginationLink
								next
								onClick={() => setCurrentpage((prev) => prev + 1)}
							>
								Next
							</PaginationLink>
						</PaginationItem>
					</Pagination>
				</Col>
			</Row>
		</>
	);
};

export default Tables;
