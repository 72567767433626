import axios from "axios";
import encode from "jwt-encode";
import { store } from "../Store/store";

 //   const jwt = encode(data, secret);
 const handleError = () => {
    // console.log(error)

    //  window.location.replace("/api-error");
};
const DefaultHeaders=()=>{
    let jwt=""
    const secret = process.env.REACT_APP_KEY;
    // let select = useSelector((state) => state?.login?.tasks);
    // console.log(select)
    let loginData =store.getState();
    let tokenObject = loginData?.login?.tasks;
    let decodedObject = { 
        email: tokenObject[0]?.email,
        role: tokenObject[0]?.empRoleName
    }
    // console.log(decodedObject)
    // console.log(tokenObject)
    if(Object.values(decodedObject).length>0){
     jwt = encode(decodedObject, secret);
    }
 

    return {
        authorization:`Bearer ${jwt}`,
        'Content-Type': 'application/json'
    }
}
const FileHeaders=()=>{
    let jwt=""
    const secret = process.env.REACT_APP_KEY;
    let loginData =store.getState();
    let tokenObject = loginData?.login?.tasks;
    let decodedObject = { 
        email: tokenObject[0]?.email,
        role: tokenObject[0]?.empRoleName
    }
    // console.log(decodedObject)
    // console.log(tokenObject)
    if(Object.values(decodedObject).length>0){
     jwt = encode(decodedObject, secret);
    }
 

    return {
        authorization:`Bearer ${jwt}`,
    }
}
const getReq = (url, headers = {}) => {

    headers = DefaultHeaders();
    // console.log(headers)
    return axios({
        headers: headers,
        method: "get",
        url: url,
    }).catch((error) => {
        if (error) {
             handleError();
            return error?.response?.status;
        }
    });
};


const getLogin = (url, headers = {}) => {
   
    return axios({

        method: "get",
        url: url,
    }).catch((error) => {
        if (error) {
			handleError();
            return error;
        }
    });
};



const deleteReq = (url, headers = {}) => {
    headers = DefaultHeaders();
    return axios({
        headers: headers,
        method: "delete",
        url: url,
    }).catch((error) => {
        if (error) {
			handleError();
            return error.response.status;
        }
    });
};

const postReq = (url, data = {}, headers = {}) => {
    headers = DefaultHeaders();
    return axios({
        method: "post",
        headers:headers,
        data: data,
        url: url,
    }).catch((error) => {
        if (error) {
			handleError();
            return error.response.status;
        }
    });
};

const fileReq = (url, data = {}, headers = {}) => {
    headers = FileHeaders();
    return axios({
        method: "post",
        headers:headers,
        data: data,
        url: url,
    }).catch((error) => {
        if (error) {
			handleError();
            return error.response.status;
        }
    });
};

const putReq = (url, data = {}, headers = {}) => {
    headers = DefaultHeaders();
    return axios({
        headers: headers,
        method: "put",
        data: data,
        url: url,
    }).catch((error) => {
        if (error) {
			handleError();
            return error.response.status;
        }
    });
};

export { getReq, postReq, deleteReq, putReq,getLogin ,fileReq,DefaultHeaders};