import { postReq } from "../Api/api";

import React, { useEffect, useState } from "react";

import {
	Table,
	Col,
	Row,
	Label,
	Input,
	Button,
	Container,
	Card,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CreatableSelect from "react-select/creatable";
import { getReq } from "../Api/api";
import { useSelector } from "react-redux";

// import REACT_APP_URL from '../.env';

const Workflow = () => {

	let url = process.env.REACT_APP_URL;
	let select = useSelector((state) => state?.login?.tasks);
	// console.log(select);
	let createdBy = select[0].employeeId;
	const [data, setdata] = useState("");
	// const[stage,setstages]=useState()

	useEffect(() => {
		getReq(`${url}workFlowConfig/getworkflow`)
			.then((res) => setdata(res.data))
			.catch((e) => console.log(e));
	}, []);

	const [state, setState] = useState(true);

	const colourOptions = [
		{ value: "Employee", label: "Employee" },
		{ value: "Manager", label: "Manager" },
	];

	const [workflowName, setWorkflowName] = useState("");
	const [selectedStages, setSelectedStages] = useState({
		stage1: null,
		stage2: null,
	});

	const handleStageSelect = (value, stage) => {
		setSelectedStages((prevStages) => ({
			...prevStages,
			[stage]: value,
		}));
	};

	const handleCreate =  () => {
		const stages = {};
		Object.entries(selectedStages).forEach(([stage, value]) => {
			if (value) {
				stages[`Stage${stage.charAt(stage.length - 1)}`] = value.value;
			}
		});

	const createdObject = {
			stages:JSON.stringify(stages),
			workflowName: workflowName,
			createdBy: createdBy,
			updatedBy: createdBy,
		};

		// console.log(createdObject);

		postReq(`${url}/workFlowConfig/workflow`, createdObject)
			.then((response) => {
				// console.log(response);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
		getReq(`${url}workFlowConfig/getworkflow`).then((res) => setdata(res.data));

		setWorkflowName("");
		setSelectedStages({
			stage1: null,
			stage2: null,
		});
	};
	return (
		<>
			<Row>
				<Col>
					<Card>
						<h2 className="heading">Create a New Workflow Below</h2>
						<Container fluid>
							<Row>
								<Col sm={4}>
									<Row className="align-items-center">
										<Col sm={4}>
											<Label className="form-label">WorkFlow Name</Label>
										</Col>
										<Col sm={8}>
											<Input
												type="text"
												style={{ paddingTop: "1%" }}
												placeholder="WorkFlow Name"
												onChange={(e) => setWorkflowName(e.target.value)}
											/>
										</Col>
									</Row>
								</Col>
								<Col sm={3}>
									<Row className="align-items-center">
										<Col sm={3}>
											<Label className="form-label">Stage 1</Label>
										</Col>
										<Col sm={9}>
											<CreatableSelect
												isClearable
												options={colourOptions}
												onChange={(value) => handleStageSelect(value, "stage1")}
											/>
										</Col>
									</Row>
								</Col>
								<Col sm={3}>
									<Row className="align-items-center">
										<Col sm={3}>
											<Label className="form-label">Stage 2</Label>
										</Col>
										<Col sm={9}>
											<CreatableSelect
												isClearable
												options={colourOptions}
												onChange={(value) => handleStageSelect(value, "stage2")}
											/>
										</Col>
									</Row>
								</Col>
								<Col sm={2}>
									<Button
										color="secondary"
										onClick={handleCreate}
										disabled={
											!workflowName ||
											!selectedStages.stage1 ||
											!selectedStages.stage2
										}>
										Create
									</Button>
								</Col>
							</Row>
						</Container>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card>
						<h2 className="heading">WorkFlows</h2>
						<Table borderless className="tableHere">
							<thead
								className="tableHeader"
								style={{ backgroundColor: "#f0f0f0" }}>
								<tr>
									<th>Workflow ID</th>
									<th>CreatedBy</th>
									<th>WorkFlow Name</th>
									<th>Stages</th>
								</tr>
							</thead>
							<tbody>
								{data &&
									data.map((row, index) => (
										<tr key={index}>
											<td>{row.id}</td>
											<td> {row.createdBy}</td>
											<td>{row.workflowName}</td>
											<td>2
												{/* {(() => {
													try {
														const parsedStages = JSON.parse(row.stages);
														return Object.keys(parsedStages).length;
													} catch (error) {
														console.error("Error parsing stages:", error);
														return "Invalid JSON";
													}
												})()} */}
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default Workflow;