import  '../CSS/Banner.css'
const Banner = ({ images, speed = 30000 }) => {
    const text="The  application  will  be under maintenance from 7PM to 7:30PM everyday. Please refrain from using it during the maintenance."
    const wordsWithSpaces =text.split(' ')
    return (
        <div style={{marginTop:"2%"}} className="inner">
        <div className="wrapper">
          {/* Use a div for text scrolling animation */}
          <div className="section_container" style={{ "--speed": `${speed}ms` }}>
        
            {/* {wordsWithSpaces.map((word, index) => (
              <div className="item" key={index}>
                <h6>{word}</h6>
              </div>
            ))} */}
           <h6 style={{color:"#F2613F"}}> The  application  will  be  under  maintenance  from  7PM  to  7:30PM  everyday.&nbsp; Please refrain from using it during the maintenance.</h6>
          </div>
        </div>
      </div>
    );
  };
  
  export { Banner };