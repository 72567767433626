import React from "react";
import CustomAccordion from "./Accordion";
import { PiSlidersHorizontalFill } from "react-icons/pi";

import "../CSS/CommunicationHistory.css";
import "bootstrap/dist/css/bootstrap.min.css";

const CommunicationHistory = (props) => {
  return (
    <div className="comm-history-container">
      <div className="comm-history-header">
        <span>Communication History</span>
        <button>
          <PiSlidersHorizontalFill size={25} />
          Filter
        </button>
      </div>

      <CustomAccordion props={props} />
    </div>
  );
};

export default CommunicationHistory;
