import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import Home from "../Pages/Home";
import { useSelector } from "react-redux";
import "../CSS/Template.css";
import SelfAssessment from "../Pages/SelfAssessment";
import Escalation from "../Pages/Escalation";
import Teams from "../Pages/Teams";
import InitAppraisal from "../Pages/InitialAppraisal";
import EmployeeStatus from "../Pages/EmployeeStatus";
import ManagerStatus from "../Pages/ManagerStatus";
import Employee_Appraisal_History from "../Pages/Employee_Appraisal_History";
// import ParametersQuestionsConfig from '../Pages/Parameters';
import ParametersQuestionsConfig from "../Pages/Configurations";
import HrApprisialHistory from "../Pages/Hr_apprisialHistory";
import Reports from "../Pages/Reports";
import MyReports from "../Pages/MyReports";
import AppraisalHistroyEmp from "../Pages/AppraisalHistoryEmp";
import Workflow from "../Pages/Workflow";
import MyTeam from "../Pages/MyTeam";
import InitializedAppraisalList from "../Pages/InitialAppraisalList";
import EscalatedListDetailView from "../Pages/EscalatedListDetailView";
import Profile from "../Pages/Profile";
import AccessError from "../Pages/AccessError"
import APIError from "../Pages/APIError";
import FAQPage from "../Pages/FAQPage";

const Router = () => {
    const select = useSelector((state) => state.login?.tasks);
    let userRole = select[0].empRoleName;
    let routes = [];

	if (userRole === 'HR Manager') {
		routes = [
			{ path: '/dashboard', element: <Dashboard /> },
			{ path: '/parameters', element: <ParametersQuestionsConfig /> },
			{ path: '/workflowconfig', element: <Workflow /> },
			{ path: '/self-assessment', element: <SelfAssessment /> },
			{ path: '/initialize-appraisal', element: <InitializedAppraisalList /> },
			{ path: '/initializeappraisal-form', element: <InitAppraisal /> },
			{ path: '/aphistory', element: <AppraisalHistroyEmp /> },
			{ path: '/manager-status', element: <ManagerStatus /> },
			{ path: '/employee-status', element: <EmployeeStatus /> },
			{ path: '/team', element: <MyTeam /> },
			{ path: '/teams', element: <Teams /> },
			{ path: '/escalation', element: <Escalation /> },
			{ path: '/escalated-view', element: <EscalatedListDetailView /> },
			{ path: '/reports', element: <Reports /> },
			{ path: '/my-reports', element: <MyReports /> },
			{ path: '/my-appraisal-history', element: <Employee_Appraisal_History /> },
			{ path: '/faq', element: <FAQPage /> },

		];
	} else if (userRole === 'Employee') {
		routes = [
			{ path: '/faq', element: <FAQPage /> },
			{ path: '/self-assessment', element: <SelfAssessment /> },
			{ path: '/my-reports', element: <MyReports /> },
			{ path: '/aphistory', element: <AppraisalHistroyEmp /> },
			{ path: '/my-appraisal-history', element: <Employee_Appraisal_History /> },
		];
	} else if (userRole === 'Manager') {
		routes = [
			{ path: '/faq', element: <FAQPage /> },
			{ path: '/dashboard', element: <Dashboard /> },
			{ path: '/self-assessment', element: <SelfAssessment /> },
			{ path: '/team', element: <MyTeam /> },
			{ path: '/manager-status', element: <ManagerStatus /> },
			{ path: '/employee-status', element: <EmployeeStatus /> },
			{ path: '/reports', element: <Reports /> },
			{ path: '/my-reports', element: <MyReports /> },
			{ path: '/my-appraisal-history', element: <Employee_Appraisal_History /> },
		];
	}
	

    return (
        <Routes>
			{userRole==='HR Manager'?<Route path= '/' element= {<Dashboard /> }/> : <Route path= '/' element= {<SelfAssessment /> }/>}
			<Route path= '*' element= {<AccessError/> }/>
			{/* <Route path= '/api-error' element={<APIError/>}/> */}
			<Route path= '/profile' element= {<Profile/> }/>
			<Route exact path="/feed-forward" component={() => "Feed Forward"} />
			<Route exact path="/help-centre" component={() => "Help Centre"} />
            {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
            ))}
        </Routes>
    );
};

export default Router;



	
