import PerformanceManagement from "../Api/PerformanceManagement";
import { getList } from "../Api/commonApi";
import React, { useEffect, useState,useRef } from "react";
import "../CSS/Employee_Appraisal_History.css";
import { Table, Button, Row, Input, Col,Modal,ModalBody,ModalHeader } from "reactstrap";
import { getReq, postReq, putReq } from "../Api/api";
import { useLocation, useNavigate } from "react-router-dom";
import Toast, { handleSuccessToast, handleErrorToast } from "./Toast";
import StepIndicator from "./StepIndicator";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa6";
import { FiArrowLeftCircle } from "react-icons/fi";


function Employee_Appraisal_History() {
  const [modal, setModal] = useState(false);
  const [modalData,setModalData]=useState()
  const toggle = () => setModal(!modal);
  const handleModal=(type,q)=>{
    if(type=="employeeComments")
    setModalData({
        header:q.dimension,
        body:q.employeeComments
    })
    else if(type=="question")
    setModalData({
      header:q.dimension,
      body:q.question
  })
    setModal(!modal)
  }
  

  const location = useLocation();
  let url = process.env.REACT_APP_URL;
  let id = location?.state?.id;
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [empid, setempid] = useState("");
  const [role, setrole] = useState("");
  const [bussunit, setbuss] = useState("");
  const [L1manager, setL1manager] = useState("");
  const [L2manager, setL2manager] = useState("");
  const [data, setdata] = useState();
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const [questionsArray, setQuestionsArray] = useState([]);
  const [showForm, setshowForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    Array(questionsArray?.length).fill(null)
  );
  const [editForm, setEditForm] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [check, setCheck] = useState(false);
  const [l1rating, setrating1] = useState()
  const [emprating, setemprating] = useState()
  const [finalRating, setfinalRating] = useState()
  const [len, setLen] = useState();

  const setrating = (questionArray) => {
    const overallManagerRating = questionArray?.reduce((sum, question) => {
      return (sum + (0.01 * question.weightage * question.managerRating));
    }, 0);

    setrating1(overallManagerRating?.toFixed(2));
  };

  const setemprating1 = (questionArray) => {
    const overallEmployeeRating = questionArray?.reduce((sum, question) => {
      return (sum + (0.01 * question.weightage * question.employeeRating));
    }, 0);
    setemprating(overallEmployeeRating?.toFixed(2));
  };


  const setfinalRating3 = () => {
    setfinalRating(l1rating);
  }

  const handleSelectChange = (event, questionIndex, field, additionalfield) => {
    const value = event.target.value;
    setQuestionsArray((prevQuestionsArray) => {
      const newQuestionsArray = [...prevQuestionsArray];
      if (field == "weightage") {
        newQuestionsArray[questionIndex][field] = value
      }
      if (field === "managerRating" || field === "employeeRating") {
        newQuestionsArray[questionIndex][field] = parseInt(value, 10);
        // console.log(newQuestionsArray)
        return newQuestionsArray;
      }
      if (field == "additionalComments") {
        if (!newQuestionsArray[questionIndex][field]) {
          newQuestionsArray[questionIndex][field] = { employee: "", manager: "" };
        }
        newQuestionsArray[questionIndex][field][additionalfield] = value;
        return newQuestionsArray
      }
      // if (field === "additionalComments") {
      //   console.log(typeof newQuestionsArray[questionIndex][field])
      //   if (typeof newQuestionsArray[questionIndex][field] === 'string') {
      //     newQuestionsArray[questionIndex][field] = { employee: "", manager: "" };
      //   }
      //   newQuestionsArray[questionIndex][field][additionalfield] = value;
      //   return newQuestionsArray;
      // }
      newQuestionsArray[questionIndex][field] = value;
      return newQuestionsArray;
    });
  };

  // const handleSubmit = () => {
  //   console.log(questionsArray)
  //   let disabled = questionsArray?.filter(
  //     (obj) => !obj?.managerComments?.trim() || !obj?.managerRating|| !obj?.weightage
  //   );
  //   if (disabled?.length > 0) {
  //     handleErrorToast("Please fill all questions!");
  //     return;
  //   } else {
  //     const apiEndpoint = `${url}api/sa/submit`;
  //     let appraisalMasterId = appraisalData?.id;
  //     let questionsArrayData = questionsArray?.map((obj) => {
  //       obj.appraisalMasterId = appraisalData?.id;
  //       return obj;
  //     });
  //     if (!check) {
  //       handleErrorToast("Please click the checkbox");
  //       return;
  //     }
  //     console.log(questionsArrayData)
  //     // Make API call to save the entire questionsArray
  //     postReq(apiEndpoint, JSON.stringify(questionsArrayData))
  //       .then((data) => {
  //         handleSuccessToast("Succesfully submitted your data");
  //         setEditForm(false);
  //         // Additional logic or state updates if needed
  //       })
  //       .catch((error) => {
  //         handleErrorToast("Data has not been saved!");
  //       });
  //     putReq(`${url}api/sa/status/${appraisalMasterId}/ManagerSubmitted`)
  //       .then((data) => {
  //         getAppraisalsByuserId();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     setDisabled(true);
  //   }
  // };

  const handleSubmit = () => {

    let disabled = questionsArray?.filter(
      (obj) => !obj.managerComments?.trim()
    );

    let managerRatingsCheck = questionsArray?.filter(
      (obj) => !obj.managerRating
    );

    if (disabled?.length > 0 || managerRatingsCheck?.length != 0) {
      handleErrorToast(
        "Please fill comments for all questions and give ratings!"
      );
    }
    else {
      // console.log(questionsArray)
      //   Calculate the total weightage
      const apiEndpoint = `${url}api/sa/submit`;
      let appraisalMasterId = appraisalData?.id;
      let questionsArrayData = questionsArray?.map((obj, index) => {
        obj.appraisalMasterId = appraisalData?.id;
        return obj;
        // Ensure additionalComments is an object
      });
      if (!check) {
        handleErrorToast("Please click the checkbox");
        return;
      }
      // console.log(questionsArrayData, '----------------------');

      // Make API call to save the entire questionsArray
      postReq(apiEndpoint, JSON.stringify(questionsArrayData))
        .then((data) => {
          handleSuccessToast("Data has been submitted successfully!");
          getAppraisalsByuserId();
          setDisabled(true);
        })
        .catch((error) => {
          handleErrorToast("Data has not been saved!");
        });

      putReq(`${url}api/sa/status/${appraisalMasterId}/ManagerSubmitted`)
        .then((data) => {
          setEditForm(false);
        })
        .catch((error) => {
          console.log(error);
        });
      setDisabled(true);
    }
  };

  const handleSave = () => {

    // Calculate the total weightage
    const apiEndpoint = `${url}api/sa/submit`;
    let appraisalMasterId = appraisalData?.id;

    let questions = questionsArray?.map((obj, index) => {
      obj.appraisalMasterId = appraisalData?.id;

      // Ensure additionalComments is an object


      return obj;
    });
    const questionsArrayData = questions?.filter((question, index) => (index < len) || (question.question || question.managerComments || question.managerRating))
    // Make API call to save the entire questionsArray
    postReq(apiEndpoint, JSON.stringify(questionsArrayData))
      .then((data) => {
        handleSuccessToast("Data has been saved successfully!");
        PerformanceManagement.getAllQuestions(appraisalData?.appraisalInitiationId).then(
          (response) => {
            setLen(response.data.length);
          }
        );
        PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
          (response) => {
            const splitquest = response?.data?.form
            setQuestionsArray(splitquest || []);
          }
        );
      })
      .catch((error) => {
        handleErrorToast("Data has not been saved!");
      });

    putReq(`${url}api/sa/status/${appraisalMasterId}/ManagerSaved`)
      .then((data) => {
      })
      .catch((error) => {
        console.log(error);
      });
    setEditForm(false);
  };

  const handleEdit = () => {
    setEditForm(!editForm);
  };

  const fetchdata = async () => {
    await getReq(`${url}login/getby?id=${id}`)
      .then((response) => {
        setname(response?.data?.name);
        setempid(response?.data?.employeeId);
        setrole(response?.data?.department);
        setbuss(response?.data?.bussinessunit);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const l1manager = async () => {
    await getReq(`${url}managers?Id=${id}`)
      .then((response) => {
        setL1manager(response.data[0]);
        // console.log(response.  data.name)
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const l2manager = async () => {
    await getReq(`${url}l2managers?Id=${id}`)
      .then((response) => {
        setL2manager(response?.data[0]);

        // console.log(response.  data.name)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAppraisalMasterId = async () => {
    try {
      const response = await getReq(
        `${url}appraisalhistroy/getAppraisalMasterId/${id}`
      );

      return response.data; // Return the master ID
    } catch (error) {
      console.log(error);
      return null;
    }
  };


  const [appraisalData, setAppraisalData] = useState(null);

  useEffect(() => {

    if (
      appraisalData?.status == "EmployeeSubmitted" ||
      appraisalData?.status == "ManagerSaved"
    ) {
      let appraisalMasterId = appraisalData?.id;
      PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
        (response) => {
          const splitquest = response?.data?.form
          setQuestionsArray(splitquest)

          // setQuestionsArray(response?.data?.form);
          setemprating1(response?.data?.form);
          setfinalRating3();
          setrating(response?.data?.form);
          setemprating1(response?.data?.form);
          setfinalRating3();
          setrating(response?.data?.form);
          setshowForm(true);
          setEditForm(true);
          setDisabled(false);
        }
      );
    }

    if (
      appraisalData?.status == "ManagerSubmitted" ||
      appraisalData?.status == "EmployeeEscalated" ||
      appraisalData?.status == "Closed"
    ) {
      setshowForm(true);
      setDisabled(true);
      let appraisalMasterId = appraisalData?.id;
      PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
        (response) => {
          const splitquest = response?.data?.form
          setQuestionsArray(splitquest)
          // setQuestionsArray(response.data.form);
          setemprating1(response?.data?.form);
          setfinalRating3();
          setrating(response?.data?.form);
        }
      );
    } else {
    }
  }, [appraisalData]);

  useEffect(() => {
    fetchdata();
    l1manager();
    l2manager();


    getAppraisalsByuserId();
  }, []);

  const getAppraisalsByuserId = async () => {
    let data = await getList(`${url}api/sa/am-status/${id}`);
    setAppraisalData(data?.appraisalMaster);


  };
  const getColorBasedOnRating = (rating) => {
    if (rating < 2) {
      return "red"; // or any other color for "Need Improvement"
    } else if (rating < 4) {
      return "orange"; // or any other color for "Consistently meeting expectations"
    } else {
      return "green"; // or any other color for "Far Exceeds the expectations"
    }
  };


  return (
    <>
      <div className="container main-content self_assessment_container">
        <button className="back-button" onClick={() => { navigate('/team') }}>
          <FiArrowLeftCircle />
          <span>Back</span>
        </button>
        <div className="pl-2">
          <StepIndicator data={appraisalData?.status} />
        </div>
        {showForm === true ? (
          <div className="app_history_container_content">
            <div>
              <div className="card card_content">
                <div className="card-body">
                  <div className="Appraisal_status">
                    <div>
                      <span className="card-title">
                        Appraisal Status:{" "}
                        <span className="appraisal_status">
                          {data && data[0]?.status}
                        </span>
                      </span>
                    </div>
                    {/* <div className="print_icon" onClick={handlePrintClick}>
                      <i class="fa fa-print  " aria-hidden="true">
                        {" "}
                        Print
                      </i>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div class="profile-info">
                        <div class="profile-icon">
                          <svg
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="25" cy="25" r="25" fill="#FE4D93" />
                            <text
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              fill="white"
                            >
                              {name
                                .split(" ")
                                .map((part) => part.charAt(0))
                                .join("")}
                            </text>
                          </svg>
                        </div>
                        <div class="profile-details">
                          <p className="Profile_name">{name}</p>
                          <p className="profile_silid"> {empid}</p>
                          <p> {role}</p>
                          <p> Business Unit:{bussunit}</p>
                        </div>
                        <div class="vl"></div>
                      </div>
                    </div>

                    <div class="col-md-8 profile-content">
                      <span className="comment_view"> Appraisal Rating: {l1rating}{" "} </span>
                      <span className="comment_view" style={{ color: getColorBasedOnRating(l1rating) }}>

                        {l1rating < 2
                          ? "(Need Improvement)"
                          : l1rating < 4
                            ? "(Consistently meeting expectations)"
                            : "(Far Exceeds the expectations)"}{" "}
                      </span>


                      <div class="Profile_comment">
                        <div className="l1_managercontent">
                          <div className="d-flex">
                            <span className="manager_content">L1 Manager:&nbsp;</span>
                            <span className="name">{L1manager}</span>
                          </div>
                          <div className="d-flex">
                            <span className="manager_content"> Manager Avg Rating: {l1rating}
                            </span>{" "}

                          </div>
                        </div>
                        <div className="l1_managercontent">
                          <div className="d-flex">
                            <span className="manager_content">L2 Manager:&nbsp;</span>{" "}
                            <span className="name">{L2manager}</span>
                          </div>
                          <div className="d-flex">
                            <span className="manager_content"> Employee Avg Rating: {emprating} </span>{" "}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {editForm === false ? (
              <div className="">
                <div className="col-md-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="table_heading">S No.</th>
                        <th className="table_heading" style={{ textAlign: "left" }}>Dimension</th>
                        <th
                          className="table_heading"
                          style={{ width: "20%", textAlign: 'left' }}
                        >
                          Goals
                        </th>
                        <th
                          className="table_heading"
                          style={{ width: "20%", textAlign: 'left' }}
                        >
                          Employee Comments
                        </th>
                        <th
                          className="table_heading"
                        >
                          Rating
                        </th>
                        <th
                          className="table_heading"
                          style={{ width: "20%", textAlign: 'left' }}
                        >
                          Manager Comments
                        </th>
                        <th
                          className="table_heading"
                        >
                          Rating
                        </th>

                        {/* <th
                          className="table_heading"
                          style={{ wquestionIdth: "20%" }}
                        >
                            Employee's  Additional Comments
                        </th>
                        <th
                          className="table_heading"
                          style={{ wquestionIdth: "20%" }}
                        >
                            Manager's  Additional Comments
                        </th> */}
                        {/* <th
                          className="table_heading"
                        >
                          Weightage
                        </th> */}
                        <th
                          className="table_heading"
                        >
                          Attached Document
                        </th>
                      </tr>
                    </thead>
                    <tbody className="table_body">
                      {questionsArray?.map((questionItem, index) => (
                        <tr key={questionItem?.questionId}>
                          <td>{index + 1}</td>
                          <td style={{ textAlign: "left" }}>{questionItem?.dimension == "work" ? "Work(Apr2023-Dec2023)" : questionItem?.dimension}</td>
                          <td style={{ textAlign: "left" }}>
                            {questionItem?.question}
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <p>{questionItem?.employeeComments}</p>
                          </td>
                          <td>
                            <p>{questionItem?.employeeRating}</p>
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <p>{questionItem?.managerComments}</p>
                          </td>
                          <td>
                            <p>{questionItem?.managerRating}</p>
                          </td>
                          {/* <td>
                            <p>{questionItem?.additionalComments?.employee}</p>
                          </td>
                          <td>
                            <p>{questionItem?.additionalComments?.manager}</p>
                          </td> */}
                          {/* <td>
                            <p>{questionItem?.weightage}</p>
                          </td> */}
                          {/* <td>
                          <p>{selectedFile[index] && <span>{selectedFile[index].name}</span>}</p>
                        </td> */}
                          <td className="text_td">
                            {questionItem?.filePath && (
                              <a
                                href={`${url}api/sa/download/${questionItem?.filePath}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {questionItem?.filePath}
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    className="form_buttons p-3 px-4"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      className="fw-semibold mx-2"
                      color="secondary"
                      onClick={handleEdit}
                      disabled={disabled}
                    >
                      Edit
                    </Button>
                    {/* <Button className='mx-2 fw-semibold' color='link'>Save as Draft</Button> */}
                    {appraisalData?.status === "ManagerSubmitted" ? (
                      <Button
                        className="fw-semibold"
                        color="success"
                        onClick={handleSubmit}
                        disabled={disabled}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        className="fw-semibold"
                        color="success"
                        onClick={handleSubmit}
                        disabled={disabled}
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <div className="col-md-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="table_heading">Dimension</th>
                        <th className="table_heading" style={{ width: "20%" }}>Goals</th>
                        <th className="table_heading" style={{ width: "20%" }}>Employee Comments</th>
                        <th className="table_heading">Rating</th>
                        <th className="table_heading" style={{ width: "20%" }}>Manager Comments</th>
                        <th className="table_heading">Rating</th>
                        {/* <th className="table_heading">Additional Comments</th> */}
                        {/* <th className="table_heading" style={{ width: "7%" }}>
                          Weightage
                        </th> */}
                        <th className="table_heading">Attached Document</th>
                      </tr>
                    </thead>
                    <tbody className="table_body">
                      {questionsArray?.map((questionItem, index) => (
                        <tr style={{ width: "35%", height: "35%" }} key={questionItem?.questionId}>
                          <td>
                            <Input
              
                              // id="exampleText"
                              disabled="true"
                              name="dimension"
                              type="text"
                              value={questionItem?.dimension == "work" ? "Work(Apr2023-Dec2023)" : questionItem?.dimension}
                              // value={appraisalData?.status === "Initialized" ? goalDimensionValue : questionItem.dimension}
                              onChange={(event) =>
                                handleSelectChange(event, index, "dimension")
                              }
                            ></Input>
                          </td>
                          <td>
                            <Input
                              // questionId="exampleText"
                              name="text"
                              type="textarea"
                              value={questionItem?.question}
                              onChange={(event) =>
                                handleSelectChange(event, index, "question")
                              }
                              rows={4}
                              readOnly
                              title="Click to see detailed view"
                              onClick={(e)=>handleModal("question",questionItem)}
                            ></Input>
                          </td>
                          <td >
                            <Input
                              title="Click to see detailed view"
                              onClick={(e)=>handleModal("employeeComments",questionItem)}
                              readOnly
                              name="text"
                              type="textarea"
                              value={questionItem?.employeeComments}
                              onChange={(event) =>
                                handleSelectChange(
                                  event,
                                  index,
                                  "employeeComments"
                                )
                              }
                              rows={4}
                            ></Input>
                          </td>
                          <td>
                            <select
                              className="rating_filter"
                              value={questionItem?.employeeRating}
                              onChange={(event) =>
                                handleSelectChange(
                                  event,
                                  index,
                                  "employeeRating"
                                )
                              }
                              disabled
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </td>
                          <td>
                            <Input
                              // questionId="exampleText"
                              name="text"
                              type="textarea"
                              value={questionItem?.managerComments}
                              onChange={(event) =>
                                handleSelectChange(
                                  event,
                                  index,
                                  "managerComments"
                                )
                              }
                              rows={4}
                            ></Input>
                          </td>
                          <td>
                            <select
                              className="rating_filter"
                              value={questionItem?.managerRating}
                              onChange={(event) =>
                                handleSelectChange(
                                  event,
                                  index,
                                  "managerRating"
                                )
                              }
                            > <option value="">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </td>
                          {/* <td>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div style={{ width: "48%" }}>
                                <label>Employee Additional Comments:</label>
                                <Input
                                disabled
                                  name="text"
                                  type="textarea"
                                  value={questionItem?.additionalComments?.employee}
                                  onChange={(event) =>
                                    handleSelectChange(event, index, "additionalComments", "employee")
                                  }
                                  rows={4}
                                />
                              </div>

                              <div style={{ width: "48%" }}>
                                <label>Manager Additional Comments:</label>
                                <Input
                               
                                  name="text"
                                  type="textarea"
                                  value={questionItem?.additionalComments?.manager}
                                  onChange={(event) =>
                                    handleSelectChange(event, index, "additionalComments", "manager")
                                  }
                                  rows={4}
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                          </td> */}
                          {/* <td>
                            <Input
                              // id="exampleText"
                              disabled="true"
                              name="weightage"
                              type="number"
                              value={questionItem?.weightage}
                              onChange={(event) =>
                                handleSelectChange(event, index, "weightage")
                              }
                              // style={{ cursor: "not-allowed" }}
                            ></Input>
                          </td> */}

                          <td className="text_td">
                            {questionItem?.filePath && (
                              <a
                                href={`${url}api/sa/download/${questionItem?.filePath}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {questionItem?.filePath}
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div style={{ border: "0.1rem solid", padding: "1% 1%", margin: '2% 2%' }}>
                    <Row className="mt-2">
                      <Col
                        style={{ paddingLeft: "4%" }}
                        xs={{
                          size: 1,
                        }}
                      >
                        {check ? (
                          <FaUserCheck
                            onClick={() => setCheck(false)}
                            size={20}
                            color="green"
                          />
                        ) : (
                          <MdCheckBoxOutlineBlank
                            onClick={() => setCheck(true)}
                            size={20}
                          />
                        )}
                      </Col>
                      <Col xs={11} className="d-flex align-items-center">
                        <span>
                          <h6>
                            I hereby acknowledge that I have personally met with my
                            employee before providing comments and ratings on
                            their performance appraisal.
                          </h6>
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <Col
                    className="form_buttons p-3 px-4"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      className="fw-semibold mx-2"
                      questionId="edit_save_btn"
                      color="secondary"
                      onClick={(handleEdit, handleSave)}
                      disabled={disabled}
                    >
                      Save
                    </Button>
                    {/* <Button className='mx-2 fw-semibold' color='link'>Save as Draft</Button> */}
                    <Button
                      className="fw-semibold"
                      color="success"
                      onClick={handleSubmit}
                      disabled={disabled}
                    >
                      Submit
                    </Button>
                  </Col>
                </div>
              </div>
            )}
            <Modal centered isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>{modalData?.header}</ModalHeader>
              <ModalBody>
               {modalData?.body}
              </ModalBody>
            </Modal>
          </div>
        ) : (
          <Table>
            <thead>
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#f2f2f2",
                      borderRadius: "5px",
                    }}
                  >
                    <h4>Employee Yet To Submit His Appraisal</h4>
                  </div>
                </td>
              </tr>
            </thead>
          </Table>
        )}
        <Toast
          setSuccessToast={setSuccessToast}
          setErrorToast={setErrorToast}
        />
      </div>
    </>
  );
}

export default Employee_Appraisal_History;
