/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../CSS/dashboard.css";
import userImage from "../Images/Primary.png";
import { Table, Badge, Button, Col } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { getReq } from "../Api/api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import faker from "faker";
import { useEffect } from "react";
import { useState } from "react";
import PieChart from "./Piechart";
import { getList } from "../Api/commonApi";
import ManagerStatus from "./ManagerStatus";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 250 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 250 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export default function Dashboard() {
  let url = process.env.REACT_APP_URL;
  const [count, setcount] = useState();
  const [Managerdata, setdata] = useState({});
  const [total, setmanagertotal] = useState();
  const [totalData,setTotalData]=useState()
  const fetchData = async () => {
    try {
      const counts = await getReq(`${url}employee/getcounts`);
      setcount(counts.data);
      // console.log("empcounts", counts.data);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };
  const sortData = (data) => {
    const order = { Submitted: 0, "In Progress": 1, "Yet to submit": 2 };
    return Object.entries(data)
      .sort((a, b) => order[a[1]] - order[b[1]])
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
  };

  const GetManagers = async () => {
    try {
      const counts = await getReq(`${url}getManagers`);
      // console.log("Called also");
      // console.log(counts);

      setmanagertotal(counts?.data?.total);
      // console.log(counts.data.total);
      const sortedData = sortData(counts.data.map);
      setdata(sortedData);
      // console.log("empcounts", counts.data.map);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };
  const [empstatus, setEmpstatus] = useState();
  const [status, setStatus] = useState();
  const empStatus = async () => {
    try {
      const res = await getReq(`${url}getemployeestatus`);
      // console.log(res.data);
      setEmpstatus(Object.keys(res.data));
      setStatus(Object.values(res.data));
    } catch (e) {
      console.log(e);
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return year + "-" + month + "-" + day;
  };
  const [todayHistory, settodayHistory] = useState([]);
  // console.log(String(getCurrentDate()));
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Format date as desired (e.g., 'MM/DD/YYYY')
  };
  const handleDownload = () => {
    // Prepare data for Excel export
    const data = totalData.map(item => ({
      
      'SIL ID': item.SILID,
      'User Fullname': item.name,
      'Status': item.status,
      'Updated Date': formatDate(item.updateddate),
      'Reporting Manager': item.ReportingManager,
      
    }));

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add column headers to the worksheet
    const headers = [ 'SIL ID', 'User Fullname', 'Status', 'Updated Date','Reporting Manager'];
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const filename=new Date().toISOString().slice(0, 10)
    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(excelBlob, `${filename}.xlsx`);
  };

  useEffect(() => {
    fetchData();
    GetManagers();
    empStatus();
    getReq(url+"appraisal/getactivestatusEmp").then((res)=>setTotalData(res.data))
    // getList(url + "appraisalsHistory/getHistOF/" + 149).then((data) =>
    //   console.log(data)
    // );
    getList(url + "appraisal/getHistoryON/" + getCurrentDate()).then((data) =>
      settodayHistory(data)
    );
  }, []);
  const sum = status?.reduce((partialSum, a) => partialSum + a, 0);
  // console.log();
  return (
    <>
      <div className="row mb-4">
        <div className="col-lg-7">
          <div className="card card-h100 main-card">
            <h2 className="heading">Stats</h2>
            <div class="container">
              <div class="row">
                <div className="col-xl-6 col-12 mb-4">
                  <div
                    class="card card-h100 justify-content-center bg-shade-pink"
                    style={{
                      // backgroundImage: "linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%)",
                      border: "0",
                    }}
                  >
                    <div class="stat-info">
                      <div className="stat-text order-lg-0 order-1">
                        <h2>{count?.employeeCount} </h2>
                        <h6>Total Employees</h6>
                      </div>
                      <div className="stat-icon order-lg-1 order-0">
                        <img src={userImage} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-12 mb-4">
                  <div
                    class="card card-h100 justify-content-center bg-shade-purple"
                    style={{
                      // backgroundImage: "linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
                      border: "0",
                    }}
                  >
                    <div class="stat-info">
                      <div className="stat-text order-lg-0 order-1">
                        <h2>{count?.deptCount}</h2>
                        <h6>Total Departments</h6>
                      </div>
                      <div className="stat-icon order-lg-1 order-0">
                        <img src={userImage} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-12 mb-4">
                  <div
                    class="card card-h100 justify-content-center bg-shade-dark-blue"
                    style={{
                      // backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",
                      border: "0",
                    }}
                  >
                    <div class="stat-info">
                      <div className="stat-text order-lg-0 order-1">
                        <h2>{count?.l1ManagersCount}</h2>
                        <h6>No. of L1 Managers</h6>
                      </div>
                      <div className="stat-icon order-lg-1 order-0">
                        <img src={userImage} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-12 mb-4">
                  <div
                    class="card card-h100 justify-content-center bg-shade-light-blue"
                    style={{
                      // backgroundImage: "linear-gradient(120deg, #a6c0fe 0%, #f68084 100%)",
                      border: "0",
                    }}
                  >
                    <div class="stat-info">
                      <div className="stat-text order-lg-0 order-1">
                        <h2>{count?.l2ManagersCount}</h2>
                        <h6>No. of L2 Managers</h6>
                      </div>
                      <div className="stat-icon order-lg-1 order-0">
                        <img src={userImage} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="card card-h100  top-performer">
            <div className="row  justify-content-between">
              <Col xs="4" style={{paddingLeft:"5%"}}>
              <h2 className="heading">Daily Status</h2>
              </Col>
              <Col xs="4" className="mt-3 "style={{paddingLeft:"12%"}}>
                <Button onClick={handleDownload}>
                  Download
                </Button>
              </Col>
            </div>
            <div className="container">
              <div className="row">
                <div
                  className="col"
                  style={{ maxHeight: "250px", overflowY: "auto" }}
                >
                  <Table responsive>
                    {todayHistory?.length === 0 && (
                      <thead>
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            <div
                              style={{
                                padding: "20px",
                                backgroundColor: "#f2f2f2",
                                borderRadius: "5px",
                              }}
                            >
                              <h4>No updates today</h4>
                            </div>
                          </td>
                        </tr>
                      </thead>
                    )}
                    {todayHistory?.length > 0 && (
                      <thead>
                        <tr>
                          <th>EmpID</th>
                          <th>Name</th>
                          <th>Status</th>
                          {/* <th>Date</th> */}
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {todayHistory?.length > 0 &&
                        todayHistory.map((todayHistory) => {
                          return (
                            <tr>
                              <td>{todayHistory.employee_id}</td>
                              <td>{todayHistory.name}</td>
                              <td>{todayHistory.status}</td>
                              {/* <td>{todayHistory.date.slice(0,10)}</td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-4">
          <div className="card card-h100 manager-status">
            <h2 className="heading">
              Manager Status{" "}
              <span style={{ marginLeft: "57px", fontSize: "12px" }}>
                {total} Submitted
              </span>
            </h2>
            <div style={{ maxHeight: "300px", overflow: "auto" }}>
              <Table borderless>
                <tbody>
                  {Managerdata &&
                    Object.entries(Managerdata).map(([name, status]) => (
                      <tr>
                        <td>{name}</td>
                        <td className="text-end">
                          <Badge
                            color={
                              status == "In Progress"
                                ? "danger"
                                : status == "Yet to submit"
                                ? "warning"
                                : "success"
                            }
                            pill
                          >
                            {status}
                          </Badge>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card card-h100 manager-status">
            <h2 className="heading">Employee Status </h2>
            {status && sum !== 0 && <PieChart data={status} lab={empstatus} />}
            {sum === 0 && (
              <Table>
                <thead>
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <div
                        style={{
                          padding: "20px",
                          backgroundColor: "#f2f2f2",
                          borderRadius: "5px",
                        }}
                      >
                        <h4>No Active Appraisal</h4>
                      </div>
                    </td>
                  </tr>
                </thead>
              </Table>
            )}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card card-h100 conversations">
            <h2 className="heading">1:1 Conversation </h2>
            <div className="container">
              <div className="row">
                <div className="col-sm">
                  <Bar options={options} data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6">
          <div className="card card-h100 top-performer">
            <h2 className="heading">Top Performers</h2>
            <div className="container">
              <div className="row">
                <div className="col">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Project Name</th>
                        <th>Team Count</th>
                        <th>Status</th>
                        <th>Manager</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">A001</th>
                        <td>Walmart</td>
                        <td>Deepak</td>
                        <td>Inprogress</td>
                        <td>AlanWalkar</td>
                      </tr>
                      <tr>
                        <th scope="row">A001</th>
                        <td>Walmart</td>
                        <td>Deepak</td>
                        <td>Inprogress</td>
                        <td>AlanWalkar</td>
                      </tr>
                      <tr>
                        <th scope="row">A001</th>
                        <td>Walmart</td>
                        <td>Deepak</td>
                        <td>Inprogress</td>
                        <td>AlanWalkar</td>
                      </tr>
                      <tr>
                        <th scope="row">A001</th>
                        <td>Walmart</td>
                        <td>Deepak</td>
                        <td>Inprogress</td>
                        <td>AlanWalkar</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card card-h100 history">
            <h2 className="heading">History</h2>
            <Table>
              <tbody>
                <tr>
                  <td>Sagarsoft Yearly Appraisal 2023-24 for Quarter 1</td>
                  <td>
                    <span className="appraisal-view">View</span>
                  </td>
                </tr>
                <tr>
                  <td>Sagarsoft Yearly Appraisal 2023-24 for Quarter 1</td>
                  <td>
                    <span className="appraisal-view">View</span>
                  </td>
                </tr>
                <tr>
                  <td>Sagarsoft Yearly Appraisal 2023-24 for Quarter 1</td>
                  <td>
                    <span className="appraisal-view">View</span>
                  </td>
                </tr>
                <tr>
                  <td>Sagarsoft Yearly Appraisal 2023-24 for Quarter 1</td>
                  <td>
                    <span className="appraisal-view">View</span>
                  </td>
                </tr>
                <tr>
                  <td>Sagarsoft Yearly Appraisal 2023-24 for Quarter 1</td>
                  <td>
                    <span className="appraisal-view">View</span>
                  </td>
                </tr>
                <tr>
                  <td>Sagarsoft Yearly Appraisal 2023-24 for Quarter 1</td>
                  <td>
                    <span className="appraisal-view">View</span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
