import { FiArrowLeftCircle } from "react-icons/fi";
import React from "react";
import EmployeeDetails from "./EmployeeDetails";
import CommunicationHistory from "./CommunicationHistory";
import "../CSS/EscalatedListDetailView.css";
import { useLocation, useNavigate } from "react-router";
import { useEffect ,useState} from "react";
import { getList } from "../Api/commonApi";
import PerformanceManagement from "../Api/PerformanceManagement";
import StepIndicator from "./StepIndicator";

import { useSelector } from "react-redux";

const EscalatedListDetailView=()=> {
  const [empdetails,setEmpdetails] = useState();
  const [questionsArray,setQuestionsArray]=useState([]);
  const [data,setData]=useState();
  const [esc, setEsc]= useState();
  const [status,setstatus]=useState();
  const location=useLocation()
  const navigate = useNavigate()
  let select = useSelector((state) => state?.login?.tasks);
	// console.log(select);
  const getAppraisalsByuserId = async ()=>{
let url = process.env.REACT_APP_URL;    
let id=location?.state?.userId;
let empId = location?.state?.employeeId
// console.log(location?.state)

let escalatedEmp =  await getList(`${url}login/getByEmpid?id=${empId}`)

    setEmpdetails(escalatedEmp);
  
    // console.log("EMP DETAILS",escalatedEmp);
    let escalationid= await getList(`${url}api/sa/empid/${empId}`)
    setstatus(escalationid?.appraisalMaster?.status)
    // console.log(escalationid)
    let escid=escalationid?.appraisalMaster?.id

   
     setEsc(escalationid)
    let escalationData =await getList(`${url}escalateMaster/getEsclationInDetailView/${escid}`)
		setData(escalationData);
	

    let questionData = await getList(`${url}api/sa/get-all-rows/${escid}`);
    setQuestionsArray(questionData?.form)
  
		}
  
  useEffect(() => {
		
		getAppraisalsByuserId()
	}, []); 

  
  return (
    <div className="escalation-details-container">
      <button className="back-button"  onClick={()=>{navigate('/escalation')}}>
        <FiArrowLeftCircle />
        <span>Back</span>
      </button>
      <StepIndicator data={status}/>
      <EmployeeDetails prop1={[empdetails,data]} appraisalFunc={getAppraisalsByuserId}/>
      <CommunicationHistory prop={[questionsArray, esc?.appraisalMaster]}/>
    </div>
  );
}

export default EscalatedListDetailView;
