import React, { useState, useEffect } from "react";
import TopBar from "../Pages/TopBar";
import classNames from "classnames";
import { Container } from "reactstrap";
import SideBar from "../Pages/SideBar";
import "../CSS/Template.css";
// import Dashboard from "./Dashboard";
import RouterF from "../Routes/Router";
import { Banner } from "./Banner";


const Template = ({ toggleSidebar, sidebarIsOpen }) => {
	const [containerClass, setContainerClass] = useState("");

	useEffect(() => {
		// Update container class based on sidebarIsOpen
		const sidebarIsOpen = document
			.querySelector(".layout")
			.classList.contains("is-open");
		setContainerClass(sidebarIsOpen ? "aside-open" : "aside-closed");
	}, [sidebarIsOpen]);

	return (
		<Container
			fluid
			className={classNames("layout", { "is-open": sidebarIsOpen })}>
			<header>
				<TopBar toggleSidebar={toggleSidebar} />
			</header>
			<main className="template-main" style={{ paddingTop: "4.75rem" }}>
				<aside className={`${containerClass}`}>
					<SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
				</aside>
				<div className="content-wrap">
					<div className="content-area">
						<Container fluid>
							<RouterF />
						</Container>
					</div>
					<footer>
						<nav>
							{/* <Banner/> */}
						</nav>
					</footer>
				</div>
			</main>
		</Container>
	);
};

export default Template;
