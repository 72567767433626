import { getReq } from "../Api/api";
import React, { useEffect, useState, useRef } from "react";
import "../CSS/Employee_Appraisal_History.css";
import { useNavigate } from "react-router";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FiArrowLeftCircle } from "react-icons/fi";
import { getCompanyList } from "../Api/commonApi";
import PerformanceManagement from "../Api/PerformanceManagement";
function AppraisalHistroyEmp() {
	const navigate=useNavigate()
	let select = useSelector((state) => state?.login?.tasks);
	const location = useLocation();
	let id = location.state.empId;
	//console.log(id);
	// let location=useLocation()
	// id=location.state.id
	let url = process.env.REACT_APP_URL;
	const componentRef = useRef();
	const [name, setname] = useState("");
	const [empid, setempid] = useState("");
	const [role, setrole] = useState("");
	const [bussunit, setbuss] = useState("");
	const [L1manager, setL1manager] = useState("");
	const [L2manager, setL2manager] = useState("");
	const [data, setdata] = useState();
	const fetchdata = async () => {
		await getReq(`${url}login/getby?id=${id}`)
			.then((response) => {
				//console.log(response);
				setname(response.data.name);
				setempid(response.data.employeeId);
				setrole(response.data.department);
				setbuss(response.data.bussinessunit);
				//console.log(response.data.name);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const l1manager = async () => {
		await getReq(`${url}managers?Id=${id}`)
			.then((response) => {
				//console.log(response.data[0]);
				setL1manager(response.data[0]);
				// //console.log(response.  data.name)
			})
			.catch((error) => {
				console.log(error);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const handlePrintClick = () => {
		window.print();
	};
	const l2manager = async () => {
		await getReq(`${url}l2managers?Id=${id}`)
			.then((response) => {
				//console.log(response.data[0]);
				setL2manager(response.data[0]);

				// //console.log(response.  data.name)
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const getAppraisalMasterId = async () => {
		try {
			const response = await getReq(
				`${url}appraisalhistroy/getAppraisalMasterId/${id}`
			);
			// console.log(response.data);
			// console.log("mastrid");
			return response.data; // Return the master ID
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	const tabledata = async () => {
		try {
			const MasterID = await getAppraisalMasterId(); // Calling getAppraisalMasterId to get MasterID
			if (MasterID) {
				const response = await getReq(`${url}appraisalhistroy/get/${MasterID}`);
				// console.log(response.data);
				setdata(response.data);
			} else {
				// console.log("Failed to get MasterID");
			}
		} catch (error) {
			// console.log(error);
		}
	};

	// Call tabledata function somewhere in your code to execute both functions

	useEffect(() => {
		fetchdata();
		l1manager();
		l2manager();
		tabledata();
	}, []);

	return (
		<div className="container" ref={componentRef}>
			<button className="back-button"  onClick={()=>{navigate('/my-appraisal-history')}}>
        <FiArrowLeftCircle />
        <span>Back</span>
      </button>
			<div className="container_content">
				<div>
					<div className="card card_content">
						<div className="card-body">
							<div className="Appraisal_status">
								<div>
									<span className="card-title">
										Appraisal Status:{" "}
										<span className="appraisal_status">Completed</span>
									</span>
								</div>
								<ReactToPrint // Wrap with ReactToPrint
									trigger={() => (
										// Print button
										<div className="print_icon" onClick={handlePrintClick}>
											<i className="fa fa-print" aria-hidden="true">
												{" "}
												Print
											</i>
										</div>
									)}
									content={() => componentRef.current} // Specify the content to be printed
								/>
							</div>
							<div className="row">
								<div className="col-md-4">
									<div class="profile-info">
										<div class="profile-icon">
											<svg
												width="50"
												height="50"
												viewBox="0 0 50 50"
												fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<circle cx="25" cy="25" r="25" fill="#FE4D93" />
												<text
													x="50%"
													y="50%"
													dominant-baseline="middle"
													text-anchor="middle"
													fill="white">
													{name
														.split(" ")
														.map((part) => part.charAt(0))
														.join("")}
												</text>
											</svg>
										</div>
										<div class="profile-details">
											<p className="Profile_name">{name}</p>
											<p className="profile_silid"> {empid}</p>
											<p> {role}</p>
											<p> Bussiness Unit:{bussunit}</p>
										</div>
										<div class="vl"></div>
									</div>
								</div>

								<div class="col-md-8 profile-content">
									<span className="comment_view">
										Appraisal Rating: 3 Consistenly meet expectations{" "}
									</span>
									<span className="comment">(view comment) </span>
									<div class="Profile_comment">
										<div className="l1_managercontent">
											<div className="d-flex">
												<span className="manager_content">L1 Manager:</span>
												<span className="name">{L1manager}</span>
											</div>
											<div className="d-flex">
												<span className="manager_content">Rating: </span>{" "}
												<span>3</span>
											</div>
										</div>
										<div className="l1_managercontent">
											<div className="d-flex">
												<span className="manager_content">L2 Manager:</span>{" "}
												<span className="name">{L2manager}</span>
											</div>
											<div className="d-flex">
												<span className="manager_content">Rating: </span>{" "}
												<span>3</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="col-md-12 appraisal_tabel">
						<table className="table">
							<thead>
								<tr>
									<th className="table_heading" style={{ width: "14%" }}>
										Questions
									</th>
									<th className="table_heading" style={{ width: "18%" }}>
										Employee Comments
									</th>
									<th className="table_heading" style={{ width: "11%" }}>
										{" "}
										Rating
									</th>
									<th className="table_heading" style={{ width: "18%" }}>
										Manager Comments
									</th>
									<th className="table_heading" style={{ width: "11%" }}>
										Rating
									</th>
									{/* <th className='table_heading' style={{ width: "11%" }}></th> */}
									<th className="table_heading" style={{ width: "18%" }}>
										Additional Comments
									</th>
									<th className="table_heading" style={{ width: "11%" }}>
										weightage
									</th>
									<th className="table_heading" style={{ width: "10%" }}>
										Attach Document
									</th>
								</tr>
							</thead>
							<tbody>
								{data &&
									data.map((item, index) => (
										<tr key={index}>
											<td>
												<p>{item.question}</p>
											</td>
											<td>
												<p>
													<textarea
														rows="4"
														defaultValue={item.employeeComments}
														readOnly></textarea>
												</p>
 b											</td>
											<td>
												<select
													className="rating_filter"
													defaultValue={item.employeeRating}
													disabled>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
												</select>
											</td>
											<td>
												<p>
													<textarea
														rows="4"
														defaultValue={item.managerComments}
														readOnly></textarea>
												</p>
											</td>
											<td>
												<select
													className="rating_filter"
													style={{ alignContent: "center" }}
													defaultValue={item.managerRating}
													disabled>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
												</select>
											</td>
											<td>
												<textarea rows="4" readOnly></textarea>
											</td>
											<td>
												<p style={{ textAlign: "center" }}>{item.weightage}</p>
											</td>
											{/* <td>
      <label htmlFor="file-upload" className="file-upload-label">
        <i className="fa fa-paperclip" aria-hidden="true"></i>
        <span>{item.filePath}</span>
      </label>
      <input id="file-upload" type="file" style={{ display: "none" }} />
    </td> */}
											<td className="text_td">
												{item.filePath && (
													<a
														href={`${url}api/sa/download/${item.filePath}`}
														target="_blank"
														rel="noopener noreferrer">
														{item.filePath}
													</a>
												)}
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AppraisalHistroyEmp;
