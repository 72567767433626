import React, { useState, useEffect } from "react";
import "../CSS/Breadcrum.css";

const StepIndicator = (props) => {
  const [Esclate, setEsclate] = useState(false);

  useEffect(() => {
    if (props.data === "EmployeeEscalated" ||props.data === "HRSubmitted") {
      setEsclate(true);
    }
  }, [props.data]);

  const steps = ["Initialized", "EmployeeSubmitted", "ManagerSubmitted", "Closed"];
  const step = ["Initialized", "EmployeeSubmitted", "ManagerSubmitted", "EmployeeEscalated", "HRSubmitted", "Closed"];
//  console.log(props?.data)
  let activeIndex = steps?.indexOf(props.data);
  let active = step?.indexOf(props.data);
  
  if(props.data==='ManagerSaved')
  {
    activeIndex=1;
    active=1;
  }
  if(active==4){
    active=5
  }
// console.log(active,"0-0-0")
  return (
    <div className="step-indicator">
      <div className="breadcrumb flat">
        {Esclate
          ? step.map((innerStep, index) => (
              <a key={index} href="#" className={index <= active ? "active" : ""}>
                {innerStep}
              </a>
            ))
          : steps.map((outerStep, index) => (
              <a key={index} href="#" className={index <= activeIndex ? "active" : ""}>
                {outerStep}
              </a>
            ))}
      </div>
    </div>
  );
};

export default StepIndicator;
