import React from 'react';
import ReactApexChart from 'react-apexcharts';

const TimeSeriesChart = () => {
  // Generate sample data for a trend over a year, with data points for each month
  const generateData = () => {
    const data = [];
    const startDate = new Date('2023-01-01').getTime();
    const endDate = new Date('2023-12-31').getTime();
    const interval = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

    for (let currentDate = startDate; currentDate <= endDate; currentDate += interval) {
      data.push({
        x: currentDate,
        y: Math.floor(Math.random() * 100), // Replace this with your actual data point
      });
    }

    return data;
  };

  const options = {
    chart: {
      type: 'line',
    },
    xaxis: {
      type: 'datetime',
    },
  };

  // Use the generateData function to create the sample data
  const data = [{
    name: 'Monthly Trend',
    data: generateData(),
  }];

  return <ReactApexChart options={options} series={data} type="line" />;
};

export default TimeSeriesChart;
