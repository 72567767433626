import ReactApexChart from 'react-apexcharts';

const PieChart = ({ data,lab }) => {
  // console.log(lab);
  const options = {
    chart: {
      type: 'donut',
    },
    labels:lab
  };

  return <ReactApexChart options={options} series={data} type="donut" />;
};

export default PieChart;
