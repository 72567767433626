import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  DropdownMenu,
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  Container,
  Button,
} from "reactstrap";
import { getReq } from "../Api/api";
import "../CSS/escalation.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EscalatedListDetailView from "./EscalatedListDetailView";

function Escalation() {
  const defaultEntriesPerPage = 15;
  const [entriesPerPage, setEntriesPerPage] = useState(defaultEntriesPerPage);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [searchfilter, setSearchFilter] = useState([]);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [apidata, setapiData] = useState({ designations: [], departments: [] });

  let url = process.env.REACT_APP_URL;
  let navigate = useNavigate();

  const fetchData = async () => {
    try {
      // console.log(url);
      const response = await getReq(
        `${url}escalateMaster/getAllEscalationDetails`
      );
    
        let result=response.data;
        let msg=response.data[0].message;
        // console.log("Result", result);
        // console.log("Message", msg);
        const esc_res = await getReq(`${url}escalateMaster/getEscalationFilters`);
        // console.log("RESPONSE", esc_res.data);
        setapiData(esc_res.data);
        if(msg==undefined)
        {
        //  console.log("hi"); 
        setData(result);
        // console.log(data);
        setList(result);
        // console.log(list);
        }
      
    } catch (error) {
      setError(error);
    }
  };
  const HandleSearch = (e) => {
    // setSearchFilter(e);
    // console.log(e);
    // console.log(typeof(e));

    if (e === "" || e === null) {
      setData(list);
      return;
    }

    const filterBySearch = Array.isArray(list)
      ? list.filter((item) => {
        // console.log("HERE");
        // console.log(typeof item);
        // console.log(item?.hasOwnProperty("employeeName"));

        if (typeof item === "object" && item?.hasOwnProperty("employeeName")) {
          console?.log("INSIDE");
          return (
            item?.employeeName?.toLowerCase()?.includes(e?.toLowerCase()) || false
          );
        }

        return false;
      })
      : [];
    console?.log(filterBySearch);
    setData(filterBySearch);
  };
  
  const DepartmentFilter = async (element) => {
    if (element !== "REMOVE FILTER" && department !== element) {
      const response = await getReq(
        `${url}escalateMaster/filter?department=${element}`
      );
      setData(response?.data);
      setDepartment(element); // Remembering the selected department
    } else if (element === "REMOVE FILTER") {
      fetchData();
      setDepartment(null); // Resetting the selected department
    }
  };

  const StatusFilter = async (element) => {
    if (element !== "REMOVE FILTER" && status !== element) {
      const response = await getReq(
        `${url}escalateMaster/filter?status=${element}`
      );
      setData(response?.data);
      setList(response?.data);
      setStatus(element); // Remembering the selected department
    } else if (element === "REMOVE FILTER") {
      fetchData();
      setStatus(null); // Resetting the selected department
    }
  };

  const DesignationFilter = async (element) => {
    if (element !== "REMOVE FILTER" && designation !== element) {
      const response = await getReq(
        `${url}escalateMaster/filter?designation=${element}`
      );
      setData(response?.data);
      setList(response?.data);
      setDesignation(element); // Remembering the selected department
    } else if (element === "REMOVE FILTER") {
      fetchData();
      setDesignation(null); // Resetting the selected department
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  // const fetchFilterDesignation = async (designation)=>
  // {

  //   try{
  //     console?.log(designation);
  //     const response =  await getReq(`${url}escalation/getByDesignation/${designation}`)
  //     const result= response?.data;
  //     setData(result);
  //     setLoading(false);
  //   }
  //   catch(error)
  //   {
  //     setError(error);
  //     setLoading(false);
  //   }
  // }
  // const fetchFilterStatus = async (status)=>
  // {

  //   try{
  //     console?.log(status);
  //     const response =await getReq(`${url}escalation/getByStatus/${status}`)
  //     const result= await response?.data;
  //     setData(result);
  //     setLoading(false);
  //   }
  //   catch(error)
  //   {
  //     setError(error);
  //     setLoading(false);
  //   }
  // }
  // const fetchFilterDepartment = async (department)=>
  // {
  //   try{
  //     console?.log(department);
  //     const response =await  getReq(`${url}escalation/getByDepartment/${department}`)
  //     const result= await response?.data;
  //     console?.log(result);
  //     setData(result);
  //     setLoading(false);
  //   }
  //   catch(error)
  //   {
  //     setError(error);
  //     setLoading(false);
  //   }
  // }
  const fixedPaginationCount = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data?.length / entriesPerPage);
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEntriesPerPageChange = (e) => {
    const selectedEntriesPerPage = parseInt(e.target.value, 10);
    setEntriesPerPage(selectedEntriesPerPage);
    setCurrentPage(1);
  };
  const getPaginationRange = () => {
    const halfPagination = Math?.floor(fixedPaginationCount / 2);
    let startPage = Math.max(1, currentPage - halfPagination);
    let endPage = Math.min(totalPages, startPage + fixedPaginationCount - 1);

    if (endPage - startPage + 1 < fixedPaginationCount) {
      startPage = Math.max(1, endPage - fixedPaginationCount + 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const indexOfLastItem = currentPage * entriesPerPage;
  const indexOfFirstItem = indexOfLastItem - entriesPerPage;
  // console.log("DATA", data);
  const currentItems = (typeof data === 'object' && Array.isArray(data)) ? data.slice(indexOfFirstItem, indexOfLastItem) : null;
  // console.log("CURRENT", currentItems);
  const [designationDropdownOpen, setDesignationDropdownOpen] = useState(false);
  const [departmentDropdownOpen, setDepartmentDropdownOpen] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);

  const toggleDesignationDropdown = () =>
    setDesignationDropdownOpen(!designationDropdownOpen);
  const toggleDepartmentDropdown = () =>
    setDepartmentDropdownOpen(!departmentDropdownOpen);
  const toggleStatusDropdown = () => setStatusDropdownOpen(!statusDropdownOpen);

  return (
    <Container className="dashboard-container">
      <div className="py-3">
        <h3>All Employees</h3>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <p>
            Show{" "}
            <Input
              className="viewcount"
              type="select"
              value={entriesPerPage}
              onChange={handleEntriesPerPageChange}
              style={{
                width: "70px",
                display: "inline-block",
                marginRight: "10px",
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </Input>{" "}
            entries
          </p>
        </div>
        <div className="py-3">
          <div className="d-flex">
            <label>Search Employee:</label>
            <Input type="text" onChange={(e) => HandleSearch(e?.target?.value)} />
          </div>
        </div>
      </div>

      <div className="text-center">
        <Table responsive cssModule={{ "table-responsive": "btnn" }}>
          <thead>
            <tr>
              <th
                style={{
                  color: "white",
                  paddingBottom: "13px",
                  textAlign: "center",
                }}
              >
                SIL-ID
              </th>
              <th
                style={{
                  color: "white",
                  paddingBottom: "13px",
                  textAlign: "center",
                }}
              >
                Employee Name
              </th>
              <th style={{ color: "white", textAlign: "center" }}>
                <ButtonDropdown
                  isOpen={designationDropdownOpen}
                  toggle={toggleDesignationDropdown}
                >
                  <DropdownToggle
                    caret
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      color: "white",
                    }}
                  >
                    <b>Designation</b>
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      position: "absolute",
                    }}
                  >
                    {apidata &&
                      apidata?.designations?.map((element, index) => {
                        return (
                          <DropdownItem
                            onClick={() => DesignationFilter(element)}
                            key={index}
                          >
                            {element}
                          </DropdownItem>
                        );
                      })}
                    <DropdownItem onClick={() => StatusFilter("REMOVE FILTER")}>
                      REMOVE FILTER
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </th>
              <th style={{ color: "white", textAlign: "center" }}>
                <ButtonDropdown
                  isOpen={departmentDropdownOpen}
                  toggle={toggleDepartmentDropdown}
                >
                  <DropdownToggle
                    caret
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      color: "white",
                    }}
                  >
                    <b>Department</b>
                  </DropdownToggle>
                  <DropdownMenu>
                    {apidata &&
                      apidata?.departments?.map((element, index) => {
                        return (
                          <DropdownItem
                            onClick={() => DepartmentFilter(element)}
                            key={index}
                          >
                            {element}
                          </DropdownItem>
                        );
                      })}
                    <DropdownItem onClick={() => StatusFilter("REMOVE FILTER")}>
                      REMOVE FILTER
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </th>
              <th
                style={{
                  color: "white",
                  paddingBottom: "13px",
                  textAlign: "center",
                }}
              >
                Escalation Initiated By
              </th>
              <th style={{ color: "white", textAlign: "center" }}>
                <ButtonDropdown
                  isOpen={statusDropdownOpen}
                  toggle={toggleStatusDropdown}
                >
                  <DropdownToggle
                    caret
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      color: "white",
                    }}
                  >
                    <b>Status</b>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => StatusFilter("Open")}>
                      Open
                    </DropdownItem>
                    <DropdownItem onClick={() => StatusFilter("Closed")}>
                      Closed
                    </DropdownItem>
                    <DropdownItem onClick={() => StatusFilter("REMOVE FILTER")}>
                      REMOVE FILTER
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </th>
              <th
                style={{
                  color: "white",
                  paddingBottom: "13px",
                  textAlign: "center",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 &&
              currentItems?.map((item) => (
                <tr key={item?.id}>
                  <th scope="row">{item?.employeeId}</th>
                  <td>{item?.employeeName}</td>
                  <td>{item?.designation}</td>
                  <td>{item?.department}</td>
                  <td>{item?.escalationInitiatedBy}</td>
                  <td>
                    {item?.status === "Close" ? (
                      <span style={{ color: "green", fontSize: "20px" }}>
                        ●
                      </span>
                    ) : (
                      <span style={{ color: "red", fontSize: "20px" }}>●</span>
                    )}
                    {" " + item?.status}
                  </td>
                  <td>
                    <Button
                      id="viewbutton"
                      size="sm"
                      onClick={() => {
                        navigate("/escalated-view", { state: item });
                      }}
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              ))}
            {data?.length === 0 && (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#f2f2f2",
                      borderRadius: "5px",
                    }}
                  >
                    <h4>No Details Found</h4>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {data?.length > 0 ? (
            <p>
              Showing {indexOfFirstItem + 1} to{" "}
              {Math.min(indexOfLastItem, data?.length)} of {data?.length} entries
            </p>
          ) : (
            <p>
              Showing {indexOfFirstItem} to{" "}
              {Math.min(indexOfLastItem, data?.length)} of {data?.length} entries
            </p>
          )}
        </div>
        {data?.length > 0 &&
          <div>
            <Pagination>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink
                  previous
                  onClick={() => handlePageClick(currentPage - 1)}
                />
              </PaginationItem>

              {getPaginationRange()?.map((pageNumber) => (
                <PaginationItem
                  key={pageNumber}
                  active={pageNumber === currentPage}
                >
                  <PaginationLink onClick={() => handlePageClick(pageNumber)}>
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink
                  next
                  onClick={() => handlePageClick(currentPage + 1)}
                />
              </PaginationItem>
            </Pagination>
          </div>
        }
      </div>
    </Container>
  );
}

export default Escalation;
