import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasks: [],
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.tasks=[]
      // console.log(Object.values(action))
      if(Object.values(action.payload).length>0)
      
      state.tasks .push( action.payload );
    },
  },
});

export const { addUser } = loginSlice.actions;

export default loginSlice.reducer;