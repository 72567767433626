import { Accordion } from 'react-bootstrap';
import CommunicationHistorySteps from "./CommunicationHistorySteps";
import "../CSS/CustomAccordion.css";
import { useState } from 'react';

function BasicExample(props) {
  let url= process.env.REACT_APP_URL;
  let questionsArray=props?.props?.prop[0];
  let Date1 = props?.props?.prop[1];
  let convertedDate =new Date(Date1?.createdDate).toString()

  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span style={{fontWeight:'600', fontSize: "0.75em", color: "#6738d9" }}>
           {convertedDate?.slice(4,10)+","+  convertedDate?.slice(10,15)}
          </span>
        </Accordion.Header>
        <Accordion.Body>
         
          <div className="col-md-12 appraisal_table">
								<table className="table self_assessment_table">
									<thead>
										<tr>
											<th className="table_heading">S No.</th>
											<th className="table_heading" style={{ width: "20%" }}>
												Question
											</th>
											<th className="table_heading">Employee Comments</th>
											<th className="table_heading">Rating</th>
											<th className="table_heading">Manager Comments</th>
											<th className="table_heading">Rating</th>
											<th className="table_heading">Additional Comments</th>
											<th className="table_heading">Weightage</th>
											<th className="table_heading">Attach Document</th>
										</tr>
									</thead>
									<tbody className="table_body">
										{questionsArray?.map((questionItem, index) => (
											<tr key={questionItem.questionId}>
												<td>{index + 1}</td>
												<td className="text_td">{questionItem.question}</td>
												<td className="text_td">
													{questionItem.employeeComments}
												</td>
												<td className="text_center_td">
													{questionItem.employeeRating}
												</td>
												<td className="text_td">
													{questionItem.managerComments}
												</td>
												<td className="text_center_td">
													{questionItem.managerRating}
												</td>
												<td className="text_td">
													{questionItem.additionalComments}
												</td>
												<td className="text_center_td">
													{questionItem.weightage}
												</td>
												<td className="text_td">
													{questionItem.filePath && (
														<a
															href={`${url}api/sa/download/${questionItem.filePath}`}
															target="_blank"
															rel="noopener noreferrer"
														>
															{questionItem.filePath}
														</a>
													)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
								
	
							</div>
        </Accordion.Body>
      </Accordion.Item>
    
    </Accordion>
  );
}

export default BasicExample;