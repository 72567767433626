import React, { useState, useEffect } from "react";
import {
	Container,
	Row,
	Col,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	Table,
	Card,
	CardBody,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormFeedback,
} from "reactstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CSS/dashboard.css";
import { useSelector } from "react-redux";
import Toast, { handleErrorToast, handleSuccessToast } from "./Toast";
import { getList, updateData, postData } from "../Api/commonApi";
import Tables from "./Tables";
import { ro } from "@faker-js/faker";
import * as XLSX from 'xlsx';


const ParametersQuestionsConfig = () => {
	let select = useSelector((state) => state?.login?.tasks);
	let url = process.env.REACT_APP_URL;
	let id = select[0].id;
	const [appraisalconfig, setappraisalconfig] = useState([]);
	const [editIndex, setEditIndex] = useState(null);
	const [isEditMode, setIsEditMode] = useState(false); // New state variable for edit mode
	const [isViewMode, setIsViewMode] = useState(false);
	const [changeConfig, setChangeConfig] = useState(false)
	useEffect(() => {
		getList(url + "GetConfig").then(data => { if (data && data.length > 0) { setappraisalconfig(data); } });
		// console.log("changes")
	}, []);

	const [config, setConfig] = useState({
		name: "",
		createdby: "",
		questionsdata: "",
	});
	const [isCardVisible, setIsCardVisible] = useState(false);
	const [isConfigVisible, setIsConfigVisible] = useState(true);
	const [isButtonVisible, setIsButtonVisible] = useState(true);
	const [question, setQuestion] = useState({
		parameter: "",
		question: "",
		description: "",
	});
	const [questionsData, setQuestionsData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isUploadOpen, setIsUploadOpen] = useState(false);
	const [errors, setErrors] = useState({});

	// Function to toggle card visibility
	const toggleCardVisibility = () => {
		setIsCardVisible(!isCardVisible);
		setIsConfigVisible(false);
		setIsButtonVisible(false);
		setEditIndex(null); // Reset editIndex when opening the form
	};

	const backToConfig = () => {
		// console.log("changes")
		// setChangeConfig(!changeConfig)
		getList(url + "GetConfig").then(data => { if (data && data.length > 0) { setappraisalconfig(data); } });
		setIsModalOpen(false);
		setQuestion({
			parameter: "",
			question: "",
			description: "",
		});
		setQuestionsData([]);
		setConfig({
			name: "",
			createdby: "",
			questionsdata: "",
		});
		setIsEditMode(false);
		setIsViewMode(false);
		setIsConfigVisible(true);
		setIsCardVisible(!isCardVisible);
		setIsButtonVisible(true);
	};
	// Function to handle input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setQuestion((prevQuestion) => ({ ...prevQuestion, [name]: value }));
	};

	const handleInputName = (e) => {
		const { name, value } = e.target;
		setConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
	};

	// Function to validate form inputs
	const validateForm = () => {
		const errors = {};
		if (!question.parameter.trim()) {
			errors.parameter = "Parameter is required";
		}
		if (!question.question.trim()) {
			errors.question = "Question is required";
		}
		if (!question.description.trim()) {
			errors.description = "Description is required";
		}
		setErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const saveQuestion = () => {
		if (validateForm()) {
			const newQuestionsData = editIndex !== null
				? questionsData.map((item, index) => index === editIndex ? question : item)
				: [...questionsData, question];

			setQuestionsData(newQuestionsData);

			setQuestion({ parameter: "", question: "", description: "" });
			setEditIndex(null);
			setQuestionsData(newQuestionsData);
		}
	};

	const onEditChange = (index) => {
		setQuestion(questionsData[index]);
		setEditIndex(index); // Set the index of the row being edited
	};

	const onDelete = (index) => {
		// console.log(index)
		setQuestionsData(questionsData.filter((_, i) => i !== index));
		if (index === editIndex) {
			setEditIndex(null); // Reset editIndex if the row being edited is deleted
		}
	};

	const submitData = () => {
		//  console.log(appraisalconfig);
	let data=appraisalconfig.filter((obj)=>obj.configName==config.name.trim())
	// console.log(data,"test", config.name.trim(), appraisalconfig)
		if (config.name.trim() && questionsData.length > 0 && data.length==0) {
			config.questionsdata = questionsData;
			config.createdby = id;
			if (isEditMode) {
				const id = getbyname(config.name, appraisalconfig);
				// console.log("in edit ......." + id, config);
				updateData(url + "api/questions/updateConfig/" + id, config).then(data => {
					if (data && data.length > 0) {
						backToConfig();
						handleSuccessToast("Configuration updated successfully");
					} else { handleErrorToast("failed to update data") }
				})
			} else {
				// console.log("in save .......");
				postData(url + "api/questions/add", config).then(data => {
					if (data && data.length > 0) {
						backToConfig();
						handleSuccessToast("Configuration added successfully");
					} else { handleErrorToast("failed to add data") }
				});
			}
			//getList(url + "GetConfig").then(data => { if (data && data.length > 0) { setappraisalconfig(data); } });

		} else {
			if(data.length>0){
				setErrors({ name: "Config Already Exists!" });
			}
			 
				
				else if (!config.name.trim()) {
					setErrors({ name: "Name is required" });
				}
				
			 else {
				handleErrorToast("Please add the questions");
			}
		}
	};

	const openpop = () => {
		if (questionsData.length > 0) {
			setIsModalOpen(true);
		} else {
			handleErrorToast("Please add the questions");
		}
	};

	// Function to close modal
	const closeModal = () => {
		setIsModalOpen(false);
		setIsUploadOpen(false)
	};

	const getconfignamebyid = (id, configs) => {
		const config = configs.find((config) => config.id === id);
		return config ? config.configName : "Configuration not found";
	};

	const getbyname = (name, configs) => {
		const config = configs.find((config) => config.configName === name);
		return config ? config.id : "Configuration not found";
	};

	const handleEdit = async (configId) => {
		getList(url + "api/questions/getConfig/" + configId).then(data => {
			if (data && data.length > 0) {
				setConfig({
					name: getconfignamebyid(data[0].configId, appraisalconfig), // Pass appraisalconfig as a parameter
					createdby: id,
					questionsdata: data.map((question) => ({
						parameter: question.parameter,
						question: question.question,
						description: question.description,
					})),
				});

				// Toggle visibility of form and hide configuration table
				setIsEditMode(true);
				setIsCardVisible(true);
				setIsConfigVisible(false);
				setIsButtonVisible(false);
				setQuestionsData(data);
			}
		})
	};

	const handleViewClick = (rowData) => {
		rowData.ID && console.log(rowData.ID)
		getList(url + "api/questions/getConfig/" + rowData.ID).then((data) => {
			if (data && data.length > 0) {
				setQuestionsData(data)
				setConfig({
					name: getconfignamebyid(data[0].id, appraisalconfig),
					createdby: id,
					questionsdata: data.map((question) => ({
						parameter: question.parameter,
						question: question.question,
						description: question.description,
					})),
				});
				setIsViewMode(true);
				setIsCardVisible(true);
				setIsConfigVisible(false);
				setIsButtonVisible(false);
			}
		});
	}


	const handleEDClick = (index, status) => {
		if (status) {
			onEditChange(index)
		} else {
			onDelete(index)
		}
	};

	const sampleCsvData = `Parameter,Question,Description\nSampleParameter1,SampleQuestion1,SampleDescription1\nSampleParameter2,SampleQuestion2,SampleDescription2`;

	const downloadSampleCsv = () => {
		const blob = new Blob([sampleCsvData], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		if (link.download !== undefined) { // feature detection
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', 'SampleQuestionsConfig.csv');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const [file, setFile] = useState();

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		// console.log(event.target.files[0]);
		if (file.type != "text/csv"){
			handleErrorToast("Please select a correct format.");
		}else{
			setFile(file);
		}
	};

	const readFile = () => {
		if (!file) {
			handleErrorToast("No file selected. Please select a file to read.");
			return;
		}
		if (file.size === 0) {
			handleErrorToast("The selected file is empty. Please select a non-empty CSV file.");
			return;
		}
		const reader = new FileReader();
		reader.onload = (e) => {
			const data = e.target.result;
			const workbook = XLSX.read(data, { type: 'binary' });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			// Convert sheet to JSON with raw headers
			const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

			if (json.length <= 1) { // Checking if there's only headers row or empty
				handleErrorToast("The CSV file is empty or does not contain readable data.");
				return;
			}
			const headers = json[0];
			const expectedHeaders = ["Parameter", "Question", "Description"];
			const hasAllExpectedHeaders = expectedHeaders.every(header => headers.includes(header));
			if (!hasAllExpectedHeaders) {
				handleErrorToast("CSV headers do not match the expected format. Please check your file and try again.");
				return;
			}
			const dataWithoutHeaders = json.slice(1);
			const mappedData = dataWithoutHeaders.map(row => ({
				parameter: row[headers.indexOf("Parameter")] || "",
				question: row[headers.indexOf("Question")] || "",
				description: row[headers.indexOf("Description")] || "",
			}));
			if (mappedData.length === 0) {
				handleErrorToast("The CSV file does not contain readable data after the headers.");
				return;
			}
			setQuestionsData(questionsData.concat(mappedData));
		};
		reader.onerror = () => handleErrorToast("An error occurred while reading the file.");
		reader.readAsBinaryString(file);
		closeModal();
	};

	const uploadModel = () => {
		setIsUploadOpen(true);
	}

	return (
		<>
			<div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
				{isConfigVisible && <h5>Configurations</h5>}
				{!isConfigVisible && ((!isViewMode && !isEditMode) ? (
					<>
						<h5>Add Configurations</h5>
						<Button className="btn btn-primary ms-auto" color="none" onClick={uploadModel}> Upload CSV </Button>
					</>
				) : (<h5>Configuration  values</h5>))}

				{isButtonVisible && (
					<Button
						className="btn btn-primary ms-auto"
						color="secondary"
						onClick={toggleCardVisibility}>
						<i className="bi bi-plus-lg"></i> Add Config
					</Button>
				)}
				{isCardVisible && (
					<Button
						outline
						color="default"
						className="ms-auto"
						onClick={backToConfig}>
						<i class="bi bi-arrow-left"></i> Back
					</Button>
				)}
			</div>
			{/* Add Config Button */}

			{isCardVisible && (
				<>
					{!isViewMode && (<>
						<Card className="pt-3">
							{/* Input fields for Parameter, Question, and Description */}
							<Row className="mt-3">
								<Col xs={12} md={4}>
									<Form>
										<FormGroup>
											<Label for="parameter">Parameter</Label>
											<Input
												type="text"
												name="parameter"
												id="parameter"
												value={question.parameter}
												onChange={handleInputChange}
												invalid={!!errors.parameter}
											/>
											<FormFeedback>{errors.parameter}</FormFeedback>
										</FormGroup>
									</Form>
								</Col>
								<Col xs={12} md={4}>
									<Form>
										<FormGroup>
											<Label for="question">Question</Label>
											<Input
												type="text"
												name="question"
												id="question"
												value={question.question}
												onChange={handleInputChange}
												invalid={!!errors.question}
											/>
											<FormFeedback>{errors.question}</FormFeedback>
										</FormGroup>
									</Form>
								</Col>
								<Col xs={12} md={4}>
									<Form>
										<FormGroup>
											<Label for="description">Description</Label>
											<Input
												type="text"
												name="description"
												id="description"
												value={question.description}
												onChange={handleInputChange}
												invalid={!!errors.description}
											/>
											<FormFeedback>{errors.description}</FormFeedback>
										</FormGroup>
									</Form>
								</Col>
							</Row>

							{/* Save Button */}
							<Row className="mt-3 justify-content-end">
								<Col xs="auto" className="text-right">
									<Button
										color="secondary"
										onClick={saveQuestion}
										style={{
											backgroundColor: "#fd4c93",
											color: "#FFFFFF",
											border: "none",
										}}>
										Save
									</Button>{" "}
								</Col>
							</Row>
						</Card>
					</>)}

					{/* Display Added Questions */}
					<h5 className="head mt-5 mb-3">Existing Questions</h5>
					<Row>
						<Col xs={12}>
							<Tables
								headers={isViewMode ? ["ID", "Parameter", "Question", "Description"] : ["ID", "Parameter", "Question", "Description", "Actions"]}
								body={questionsData.map((item, index) => (
									{
										"ID": index + 1,
										"parameter": item.parameter,
										"question": item.question,
										"description": item.description,
									}))}
								handleEDClick={!isViewMode ? handleEDClick : undefined}
							/>
						</Col >
					</Row >
					{/* Submit Button */}
					<Row className="mt-3 justify-content-end">
						<Col xs="auto">
							{(isEditMode || isViewMode) ? (<>
								{(!isEditMode && false) && (<>
									<Button
										color="secondary"
										onClick={submitData}
										style={{
											backgroundColor: "#fd4c93",
											color: "#FFFFFF",

											border: "none",
										}}>
										Edit
									</Button>
								</>)}
								{!isViewMode && (<>
									<Button
										color="secondary"
										onClick={submitData}
										style={{
											backgroundColor: "#fd4c93",
											color: "#FFFFFF",
											border: "none",
										}}>
										Update
									</Button>
								</>)}

							</>
							) : (
								<Button
									color="secondary"
									onClick={openpop}
									style={{
										backgroundColor: "#fd4c93",
										color: "#FFFFFF",
										border: "none",
									}}>
									Submit
								</Button>
							)}
						</Col>
					</Row>
				</>
			)}

			{/* Configuration Table */}
			{
				isConfigVisible && (
					<Tables
						headers={["ID", "Configuration ID", "Configuration Name", "View Link"]}
						body={appraisalconfig.map(item => ({
							"ID": item.id,
							"ConfigID": item.id,
							"ConfigName": item.configName,
						}))}
						handleViewClick={handleViewClick}
					/>
				)
			}

			{/* Modal for upload */}
			<Modal isOpen={isUploadOpen} toggle={closeModal}>
				<ModalHeader toggle={closeModal}>
					Upload File
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<Row>
								<Col>
									<Label for="questionsfile"> Upload CSV to load questions </Label>
									<Input
										id="questionsfile"
										type="file"
										name="file"
										accept=".csv"
										onChange={handleFileChange}
									/>
								</Col>
							</Row>
							<Row>
								<Col>

								</Col>
							</Row>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button className="btn ms-auto" color="secondary" style={{ margin: "1vh" }} onClick={downloadSampleCsv}> Get Sample CSV <i class="bi bi-download" ></i></Button>
					<Button
						color="secondary"
						onClick={readFile}
						style={{
							marginBlock: "3vh",
							backgroundColor: "#fd4c93",
							color: "#FFFFFF",
							border: "none",
						}}>
						Read
					</Button>{" "}
					<Button
						color="error"
						onClick={closeModal}
						style={{
							backgroundColor: "#fd4c93",
							color: "#FFFFFF",
							border: "none",
						}}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>

			{/* Modal for Input */}
			<Modal isOpen={isModalOpen} toggle={closeModal}>
				<ModalHeader toggle={closeModal}>
					Enter Configuration Details
				</ModalHeader>
				<ModalBody>
					<Form>
						<FormGroup>
							<Label for="name">Configuration Name</Label>
							<Input
								type="text"
								name="name"
								id="name"
								value={config.name}
								onChange={handleInputName}
								invalid={!!errors.name}
							/>
							<FormFeedback>{errors.name}</FormFeedback>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						onClick={submitData}
						style={{
							backgroundColor: "#fd4c93",
							color: "#FFFFFF",
							borderless: true,
							border: "none",
						}}>
						Submit
					</Button>{" "}
					<Button
						color="secondary"
						onClick={closeModal}
						style={{
							backgroundColor: "#fd4c93",
							color: "#FFFFFF",
							border: "none",
						}}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			<Toast></Toast>
		</>
	);
};

export default ParametersQuestionsConfig;
