import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Table,
  Row,
  Col,
  Card,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Container,
} from "reactstrap";
import PerformanceManagement from "../Api/PerformanceManagement";
import "../CSS/selfAssessment.css";
import { DefaultHeaders, getLogin } from "../Api/api";
import { useSelector } from "react-redux";
import { getList, postData } from "../Api/commonApi";
import Toast, { handleSuccessToast, handleErrorToast } from "./Toast";
import { putReq, postReq, getReq, deleteReq, fileReq } from "../Api/api";
import Select from "react-select";
import StepIndicator from "./StepIndicator";
import scale from './LoginPage/Images/image.png'
import { useNavigate } from "react-router";
import { Banner } from "./Banner";
function SelfAssessment() {
  const navigate = useNavigate();
  let url = process.env.REACT_APP_URL;
  const [appraisal_initiated, setappraisal_initiated] = useState();
  const [questionsArray, setQuestionsArray] = useState([]);
  const [selectedFile, setSelectedFile] = useState(
    Array(questionsArray?.length).fill(null)
  );
  const [closemodal, setCloseModal] = useState(false);
  const [escalationmodal, setEscalationModal] = useState(false);
  const [appraisalData, setAppraisalData] = useState(null);
  const [editForm, setEditForm] = useState(false);
  const [employeescalated, setEmployeeEscalated] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [len, setLen] = useState();
  const optionsEscalation = [
    { value: "Unsatisfactory", label: "Unsatisfactory" },
    { value: "Others", label: "Others" },
  ];
  const [hrState, setHrState] = useState();
  const [hrcomments, setHrComments] = useState();
  const optionsClose = [
    { value: "Satisfactory", label: "Satisfactory" },
    { value: "Others", label: "Others" },
  ];

  const [disabled, setDisabled] = useState(false);
  const [showEscalation, setshowEscalation] = useState(false);
  const [dropdown, setDropdown] = useState("");
  const [comment, setComment] = useState("");

  const [addGoalModal, setAddGoalModal] = useState(false);
  const periods=[
    {
      value:"Apr2023-Dec2023",label:"Apr2023-Dec2023"
      
    },{
      value:"Jan2024-Mar2024",label:"Jan2024-Mar2024"
    }
  ]
  const work=[
    {
      value:"work",label:"work"
    }
  ]
    const goalDimensions = [
    { value: "Organisation", label: "Organisation" },
    { value: "People", label: "People" },
    { value: "Customer", label: "Customer" },
    { value: "Work", label: "Work" },
    { value: "Self", label: "Self" },
  ];
  const [goalDimensionValue, setGoalDimensionValue] = useState("");
  const [period,setPeriod]=useState()

  const [message,setMessage]=useState("")
  const closetoggle = () => setCloseModal(!closemodal);

  const submitEscalation = () => {
    let appraisalMasterId = appraisalData?.id;
    const escalationObject = {
      reason: dropdown,
      comments: comment,
      status: "open",
    };
    if (dropdown && comment?.trim()) {
      const response = postReq(
        `${url}escalateMaster/addEscalation/${employeeId}`,
        escalationObject
      );
    } else {
      handleErrorToast("Please Fill All Details");
      return;
    }

    setComment("");
    setDropdown("");
    putReq(`${url}api/sa/status/${appraisalMasterId}/EmployeeEscalated`)
      .then((data) => {
        handleSuccessToast("Escalation is successfully submitted");
        setEmployeeEscalated(false);
        setEscalationModal(!escalationmodal);
        getAppraisalsByuserId();
      })
      .catch((error) => {
        console.log(error);
      });

    // window.location.reload();
  };

  let select = useSelector((state) => state.login?.tasks);
  const employeeId = select[0].id;

  const handleFileChange = async (event, questionIndex) => {
    const file = event.target.files[0];
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await fileReq(`${url}api/sa/upload`, formData);

      if (response?.data?.message === "success") {

        setQuestionsArray((prevQuestionsArray) => {
          const newQuestionsArray = [...prevQuestionsArray];
          newQuestionsArray[questionIndex].filePath = response?.data?.filepath; // Update the file path in the state
          return newQuestionsArray;
        });
      } else {
        console.error("Failed to upload file:", response.statusText);
        // Handle error or show a notification to the user
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error or show a notification to the user
    }
  };

  const handleClearFile = async (id, questionIndex) => {
    // Make API call to clear the file
    setQuestionsArray((prevQuestionsArray) => {
      const newQuestionsArray = [...prevQuestionsArray];
      newQuestionsArray[questionIndex].filePath = null;
      return newQuestionsArray;
    });
    try {
      const response = await deleteReq(`${url}api/sa/deleteFile/${id}`);

      if (response?.data?.message === "success") {
        // setQuestionsArray((prevQuestionsArray) => {
        //   const newQuestionsArray = [...prevQuestionsArray];
        //   newQuestionsArray[questionIndex].filePath = null;
        //   return newQuestionsArray;
        // });
      } else {
        console.error("Failed to clear file:", response.statusText);
        // Handle error or show a notification to the user
      }
    } catch (error) {
      console.error("Error clearing file:", error);
      // Handle error or show a notification to the user
    }
  };

  const hrCommentsfunc = async () => {
    let appraisalMasterId = appraisalData?.id;
    const response = await getReq(
      `${url}escalateMaster/showHrComments/${appraisalMasterId}`
    );
    setHrComments(response?.data);
  };

  useEffect(() => {
    if (hrState) {
      hrCommentsfunc();
    }
  }, [hrState]);

  const closeAppraisal = () => {
    let appraisalMasterId = appraisalData?.id;
    if (comment && dropdown) {
      const escalationObject = {
        reason: dropdown,
        comments: comment,
        status: "Close",
      };

      putReq(`${url}api/sa/status/${appraisalMasterId}/Closed`)
        .then((data) => {
          setEmployeeEscalated(!employeescalated);
          // getAppraisalsByuserId();
        })
        .catch((error) => {
          console.log(error);
        });
      // const response = postReq(
      //   `${url}escalateMaster/addEscalation/${employeeId}`,
      //   escalationObject
      // );
      handleSuccessToast("Form has been submitted successfully.");
      setComment("");
      setDropdown("");
      setCloseModal(!closemodal);
    } else {
      handleErrorToast("Please Fill All Details");
    }
  };
  const escalationtoggle = () => {
    setEscalationModal(!escalationmodal);
  };

  const handleSelectChange = (event, questionIndex, field, additionalfield) => {
    const value = event.target.value;

    if (parseFloat(value) <= 0) {
      handleErrorToast("Zero and Negative values are not allowed!");
      return;
    }

    setQuestionsArray((prevQuestionsArray) => {
      const newQuestionsArray = [...prevQuestionsArray];
      if (field === "managerRating" || field === "employeeRating") {
        newQuestionsArray[questionIndex][field] = parseInt(value, 10);

        return newQuestionsArray;
      }

      if (field === "weightage") {
        newQuestionsArray[questionIndex][field] = parseInt(value, 10);
      }

      if (field == "additionalComments") {
        if (!newQuestionsArray[questionIndex][field]) {
          newQuestionsArray[questionIndex][field] = {
            employee: "",
            manager: "",
          };
        }
        newQuestionsArray[questionIndex][field][additionalfield] = value;
     
        return newQuestionsArray;
      }
      // if (field === "additionalComments") {
      //   console.log(typeof newQuestionsArray[questionIndex][field])
      //   if (typeof newQuestionsArray[questionIndex][field] === 'string') {
      //     newQuestionsArray[questionIndex][field] = { employee: "", manager: "" };
      //   }
      //   newQuestionsArray[questionIndex][field][additionalfield] = value;
      //   return newQuestionsArray;
      // }
      newQuestionsArray[questionIndex][field] = value;
      return newQuestionsArray;
    });
  };
  // const didInitializeRef = useRef(false);
  useEffect(() => {
   
    if (appraisalData?.status === "Initialized") {
   
      // let appraisalInitId = appraisalData?.appraisalInitiationId;
     
      // PerformanceManagement.getAllQuestions(appraisalInitId).then(
      //   (response) => {
          

      //     const updatedData = response?.data?.map((row) => ({
      //       ...row,
      //       weightage: Math.ceil(100/response?.data?.length),
      //     }));
      //     setLen(response.data.length);
         
      //     setQuestionsArray(updatedData);

      //     setMessage(response?.data?.message)
      //   }
      // );
      setEditForm(true);
      setshowEscalation("employee_initialized");
      // setEditForm(true)
    }

    if (appraisalData?.status === "saved") {
      let appraisalMasterId = appraisalData?.id;
      PerformanceManagement.getAllQuestions(appraisalData?.appraisalInitiationId).then(
        (response) => {
       
          setLen(response.data.length);
        }
      );
      PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
        (response) => {
          // setQuestionsArray(response?.data?.form);
          const splitquest = response?.data?.form
         
          setQuestionsArray(splitquest || []);
          setMessage(response?.data?.message)
       
        }
      );
      setEditForm(true);
      setshowEscalation("employee_initialized");
      // setEditForm(true)
    }
    if (appraisalData?.status === "EmployeeSubmitted" ||appraisalData?.status === "ManagerSaved") {
      let appraisalMasterId = appraisalData?.id;
      PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
        (response) => {
          setQuestionsArray(response?.data?.form);
          
  
        }
      );
      setDisabled(true);
    }

    if (appraisalData?.status === "ManagerSubmitted") {
      let appraisalMasterId = appraisalData?.id;
      PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
        (response) => {
          if (response?.data?.form?.length >= 1) {
            const splitquest = response?.data?.form
            setQuestionsArray(splitquest || []);
         
          }
        }
      );
      setshowEscalation("employee_escalation");
      setDisabled(true);
      setEmployeeEscalated(false);
    }

    if (appraisalData?.status === "Closed") {
      let appraisalMasterId = appraisalData?.id;
      PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
        (response) => {
          if (response?.data?.form?.length >= 1) {
            const splitquest = response?.data?.form
           
            setQuestionsArray(splitquest || []);
         
          }
        }
      );
      setEditForm(false);
      setshowEscalation("Closed");
      setEmployeeEscalated(true);
      setDisabled(true);
    }
    if (appraisalData?.status === "HRSubmitted") {
      let appraisalMasterId = appraisalData?.id;
      PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
        (response) => {
          if (response?.data?.form?.length >= 1) {
            const splitquest = response?.data?.form
           
            setQuestionsArray(splitquest || []);
          }
        }
      );
      setEditForm(false);
      setshowEscalation("hr_comments_submitted");
      setDisabled(true);
      setHrState(true);
    }
    if (appraisalData?.status === "EmployeeEscalated") {
      let appraisalMasterId = appraisalData?.id;
      PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
        (response) => {
          if (response.data?.form?.length >= 1) {
            const splitquest = response?.data?.form
          
            setQuestionsArray(splitquest || []);
          }
        }
      );
      setshowEscalation("employee_escalation");
      setEmployeeEscalated(true);
      setDisabled(true);
    }
  }, [appraisalData]);

  useEffect(() => {
    getAppraisalsByuserId();
  }, []);

  const getAppraisalsByuserId = async () => {
    
    let data = await getList(`${url}api/sa/am-status/${select[0].id}`);
    setappraisal_initiated(data?.message);
    setAppraisalData(data?.appraisalMaster || null);
  };

  const handleSubmit = () => {
   
    let disabled = questionsArray?.filter(
      (obj) => !obj.employeeComments?.trim()
    );

    let employeeRatingsCheck = questionsArray?.filter(
      (obj) => !obj.employeeRating
    );
    const selectedDimensions =[... new Set(questionsArray.map((obj) => obj.dimension))];
      // console.log(selectedDimensions);
    const totalWeightage = questionsArray?.reduce(
      (sum, question) => sum + parseInt(question.weightage, 10),
      0
    );

    if (disabled?.length > 0 || employeeRatingsCheck?.length != 0) {
      handleErrorToast(
        "Please fill comments for all questions and give ratings!"
      );
    }
    else if (selectedDimensions?.length!=6) {
      handleErrorToast("Please select at least one goal from each dimension.");
    }
    // else if(totalWeightage !== 100) {
    //   handleErrorToast("Please adjust the weightage to be exactly 100.");
    // }
    // else if (questionsArray.map(question => question.weightage) <= 1) {
    //   handleErrorToast("Zero and Negative values are not allowed!");
    // }
    else {
      // console.log(questionsArray)
      //   Calculate the total weightage
      const apiEndpoint = `${url}api/sa/submit`;
      let appraisalMasterId = appraisalData?.id;
      let questionsArrayData = questionsArray?.map((obj, index) => {
        obj.appraisalMasterId = appraisalData?.id;
        return obj;
        // Ensure additionalComments is an object
      });

      // Make API call to save the entire questionsArray
      postReq(apiEndpoint, JSON.stringify(questionsArrayData))
        .then((data) => {
          handleSuccessToast("Data has been submitted successfully!");
          getAppraisalsByuserId();
          setDisabled(true);
          
        })
        .catch((error) => {
          handleErrorToast("Data has not been saved!");
        });

      putReq(`${url}api/sa/status/${appraisalMasterId}/EmployeeSubmitted`)
        .then((data) => {
          setEditForm(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
 
  const handleSave = () => {
    
    // Calculate the total weightage
    const apiEndpoint = `${url}api/sa/submit`;
    let appraisalMasterId = appraisalData?.id;
  
    let questions = questionsArray?.map((obj, index) => {
      obj.appraisalMasterId = appraisalData?.id;

      // Ensure additionalComments is an object
     

      return obj;
    });
    const questionsArrayData=questions?.filter((question,index)=>(index<len)||(question.question||question.employeeComments||question.employeeRating||question.dimension))
    // Make API call to save the entire questionsArray
    // console.log(questionsArrayData)
    postReq(apiEndpoint, JSON.stringify(questionsArrayData))
      .then((data) => {
        handleSuccessToast("Data has been saved successfully!");
        PerformanceManagement.getAllQuestions(appraisalData?.appraisalInitiationId).then(
          (response) => {
            setLen(response.data.length);
          }
        );  
        PerformanceManagement.getAllSelfAssessmentData(appraisalMasterId).then(
          (response) => {
            const splitquest = response?.data?.form
            setQuestionsArray(splitquest || []);
          }
        );
      })
      .catch((error) => {
        handleErrorToast("Data has not been saved!");
      });

    putReq(`${url}api/sa/status/${appraisalMasterId}/saved`)
      .then((data) => {
      })
      .catch((error) => {
        console.log(error);
      });
    setEditForm(false);
  };

  const handleEdit = () => {
    setEditForm(!editForm);
  };

  const deleteQuestionById = async (questionId) => {
    try {
      const response = await deleteReq(
        `${url}api/sa/deleteById/${questionId}`
      );
      if (response.status === 200) {
        handleSuccessToast(`${questionId} has been deleted successfully!`);
        // Handle any additional logic or state updates if needed
      } else {
        if (!questionId) {
          handleSuccessToast('Row deleted successfully!');
        } else {
          handleErrorToast(`Error deleting ${questionId}, please try again!`);
        }
        // Handle error or show a notification to the user
      }
    } catch (error) {
      console.error("Error deleting question:", error);
      // Handle error or show a notification to the user
    }
  };

  const handleDeleteRow = async (questionIndex, questionId) => {

    await deleteQuestionById(questionId);
    const del=questionsArray?.filter((question,index)=>index!=questionIndex)
    setQuestionsArray(del)
  };

  const handleAddGoal = () => {
    setEditForm(true);
    const countWork=questionsArray?.filter((item)=>item.dimension=="work")
    if(period=="Apr2023-Dec2023" && countWork.length>=3){
      handleErrorToast("cannot add more than 3 questions for the selected period")
      return
    }
    const lastQuestion = questionsArray?.length;
    // console.log(lastQuestion);
    const cate1 = ['Organisation', 'People', 'Self'];
    const cate2 = ['Customer', 'Work','work'];

    if (lastQuestion >= 0) {
      // console.log(lastQuestion);
      // console.log(questionsArray);
      setQuestionsArray((prevQuestionsArray) => [
        ...prevQuestionsArray,
        {
          id: lastQuestion + 1, // generate a new questionId
          question: "",
          dimension: goalDimensionValue,
          period:period
          // ... (copy other properties as needed)
        },
      ]);    
      // console.log(questionsArray);
      setSelectedFile((prevSelectedFiles) => [...prevSelectedFiles, null]);
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth", // You can change this to 'auto' for instant scroll
      });
    }
    let cat1 = questionsArray.filter(question => question.dimension === 'Organisation' || question.dimension === 'People' || question.dimension === 'Self').length;
    // console.log(cat1)
    let cat2 = questionsArray.filter(question => question.dimension === 'Customer' || question.dimension === 'Work'||question.dimension=="work").length;
    // console.log(cat2)
    if(cate1.includes(goalDimensionValue)){
      cat1++;
    }
    else{
      cat2++;
    }
    let weightage1= 20/cat1;
    // console.log(weightage1)
    let weightage2= 80/cat2;
    // console.log(weightage2)
    setQuestionsArray((prev)=>prev.map(question => ({
      ...question,
      weightage: cate1.includes(question.dimension) ? weightage1 : weightage2
  }))     
    );
    setGoalDimensionValue("")
    setPeriod("")
    setAddGoalModal(false);
  };

  const handleAddGoalOpen = () => {
    setAddGoalModal(true);
  }

  const handleAddGoalClose = () => {
    setAddGoalModal(false);
    setGoalDimensionValue("");
  }

  const downloadFile = (url) => {
    const headers = DefaultHeaders();
    window.open(url, "_blank");
  };

  return (
    <>
      {!appraisalData ? (
        <Container className="teams-container" xs="3" md="8">
          <Table className="mt-4">
            <tbody>
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#f2f2f2",
                      borderRadius: "5px",
                    }}
                  >
                    <h4>Appraisal Not Yet Initiated</h4>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      ) : (
        
        <div className="container self_assessment_container py-3">
          <div className="d-flex justify-content-between pb-2">
            <div>
              <StepIndicator data={appraisalData?.status} />
            </div>
            <div className="goal_button">
              <Button
                className="fw-semibold"
                onClick={handleAddGoalOpen}
                disabled={disabled}
              >
                <i class="bi bi-plus-lg"></i> Add a Goal
              </Button>
            </div>
            {addGoalModal && (
              <Modal isOpen={addGoalModal} centered>
                <ModalHeader toggle={handleAddGoalClose}>
                  Add a Goal
                </ModalHeader>
                <ModalBody>
                  <Row className="d-flex">
                    <h5>Period: </h5>
                    <Select
                      required
                      defaultValue={{
                        value: "Select Category",
                        label: "Select Category",
                      }}
                      options={periods}
                      onChange={(selectedOption) =>
                        {
                          setPeriod(selectedOption.value);
                          setGoalDimensionValue("")
                        }
                      }
                    />
                  </Row>
                  <Row className="d-flex mt-3">
                    <h5>Dimension: </h5>
                    <Select
                      required
                      defaultValue={{
                        value: "Select Category",
                        label: "Select Category",
                      }}
                      options={period=="Apr2023-Dec2023"?work:goalDimensions}
                      onChange={(selectedOption) =>
                        setGoalDimensionValue(selectedOption.value)
                      }
                    />
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleAddGoal} disabled={goalDimensionValue.trim()===""}>
                    Submit
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
          <div className="self_assessment_container_content">
            <div>
              <div className="card card_content">
                <div className="card-body">
                  <div className="row">
                    <div>
                      <p className="card_heading fw-semibold">
                        Guidelines on how to complete Self Assessment form.
                      </p>
                    </div>
                    <div>
                      <p className="card_details">
                        Before you begin filling out the form, please ensure
                        that you have a one-on-one conversation with your
                        manager. Please list all your goals and achievements for
                        the current appraisal period . Before providing ratings
                        and comments, read each question clearly.
                      </p>
                    </div>
                    <div className="rating-container">
                      <img src={scale} style={{
                         width:"90%", height:"90%"
                      }}>
                      </img>
                      <h5 >
                       For more details,visit  <span   className="btn-link Hov" onClick={()=>navigate('/faq')}>Read.me</span>
                      </h5>
                      <Banner className="mt-6"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {editForm === false ? (
              <div>
                <div className="col-md-12 appraisal_table">
                  <table className="table self_assessment_table">
                    <thead>
                      <tr>
                        <th className="table_heading">S No.</th>
                        <th className="table_heading">Dimension</th>
                        <th className="table_heading" style={{ width: "20%" }}>
                          Goals
                        </th>
                        <th className="table_heading" style={{ width: "20%" }}>Employee Comments</th>
                        <th className="table_heading">Rating</th>
                        <th className="table_heading" style={{ width: "20%" }}>Manager Comments</th>
                        <th className="table_heading">Rating</th>
                        {/* <th className="table_heading">
                          Employee Additional Comments
                        </th>
                        <th className="table_heading">
                          Manager Additional Comments
                        </th> */}
                        {/* <th className="table_heading">Weightage</th> */}
                        <th className="table_heading">Attach Document</th>
                      </tr>
                    </thead>
                    <tbody className="table_body">
                      {questionsArray?.map((questionItem, index) => (
                        <tr key={questionItem.questionId}>
                          <td>{index + 1}</td>
                          <td className="text_td">
                            {questionItem?.dimension=="work"?"Work(Apr2023-Dec2023)":questionItem?.dimension}
                          </td>
                          <td className="text_td">{questionItem.question}</td>
                          <td className="text_td">
                            {questionItem?.employeeComments}
                          </td>
                          <td className="text_center_td">
                            {questionItem?.employeeRating}
                          </td>
                          <td className="text_td">
                            {appraisalData?.status!=="ManagerSaved"?questionItem.managerComments:""}
                          </td>
                          <td className="text_center_td">
                            {questionItem?.managerRating}
                          </td>
                          {/* <td className="text_td">
                            <p>{questionItem?.additionalComments?.employee}</p>
                          </td>

                          <td>
                            <p>{appraisalData?.status!=="ManagerSaved"?questionItem?.additionalComments?.manager:""}</p>
                          </td> */}
                          {/* <td className="text_center_td">
                            {questionItem?.weightage}
                          </td> */}
                          <td className="text_td">
                            {questionItem?.filePath && (
                              <a
                                href={`${url}api/sa/download/${questionItem.filePath}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {questionItem?.filePath}
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {showEscalation === "employee_escalation" ? (
                    <div className="escalation_div">
                      <Button
                        color="danger"
                        onClick={escalationtoggle}
                        className="fw-semibold mx-2"
                        disabled={employeescalated}
                      >
                        Escalate
                      </Button>
                      <Modal
                        isOpen={escalationmodal}
                        toggle={escalationtoggle}
                        className="modal-dialog-centered"
                      >
                        <ModalHeader toggle={escalationtoggle}>
                          Escalation
                        </ModalHeader>
                        <ModalBody>
                          <Row className="mb-3">
                            <Col>
                              <Select
                                required
                                defaultValue={{
                                  value: "Reason For Escalation",
                                  label: "Reason For Escalation",
                                }}
                                options={optionsEscalation}
                                onChange={(selectedOption) =>
                                  setDropdown(selectedOption.value)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <Input
                                // id="exampleText"
                                name="text"
                                type="textarea"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                rows={4}
                                placeholder="Comments"
                                required
                              ></Input>
                              {/* <textarea onChange={(e)=>setComment(e.target.value)} placeholder='Comments'  rows="4" cols="50" /> */}
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={submitEscalation}>
                            Submit
                          </Button>{" "}
                          <Button color="secondary" onClick={escalationtoggle}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                      <Button
                        color="danger"
                        onClick={closetoggle}
                        className="fw-semibold"
                        disabled={employeescalated}
                      >
                        Close
                      </Button>
                      <Modal
                        isOpen={closemodal}
                        toggle={closetoggle}
                        className="modal-dialog-centered"
                      >
                        <ModalHeader toggle={closetoggle}>FeedBack</ModalHeader>
                        <ModalBody>
                          <Row className="mb-3">
                            <Col>
                              <Select
                                required
                                defaultValue={{
                                  value: "FeedBack",
                                  label: "FeedBack",
                                }}
                                options={optionsClose}
                                onChange={(selectedOption) =>
                                  setDropdown(selectedOption.value)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <Input
                                // id="exampleText"
                                name="text"
                                type="textarea"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                rows={4}
                                placeholder="Comments"
                                required
                              ></Input>
                              {/* <textarea placeholder='Comments' onChange={(e)=>setComment(e.target.value)}  rows="4" cols="50" /> */}
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={closeAppraisal}>
                            Submit
                          </Button>{" "}
                          <Button color="secondary" onClick={closetoggle}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  ) : showEscalation === "employee_initialized" ||
                    showEscalation === "employee_submitted" ? (
                    <div
                      className="form_buttons p-3 px-4"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        className="fw-semibold mx-2"
                        color="secondary"
                        disabled={disabled}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                      {/* <Button className='mx-2 fw-semibold' color='link'>Save as Draft</Button> */}
                      {appraisalData?.status === "EmployeeSubmitted" ? (
                        <Button
                          className="fw-semibold"
                          color="success"
                          onClick={handleSubmit}
                          disabled={disabled}
                          id="save_btn"
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          className="fw-semibold"
                          color="success"
                          onClick={handleSubmit}
                          disabled={disabled}
                          id="save_btn"
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  ) : showEscalation === "hr_comments_submitted" ? (
                    <div className="escalation_div">
                      <Button
                        color="danger"
                        onClick={escalationtoggle}
                        className="fw-semibold mx-2"
                      >
                        HR COMMENTS
                      </Button>
                      <Modal
                        isOpen={escalationmodal}
                        toggle={escalationtoggle}
                        className="modal-dialog-centered"
                      >
                        <ModalHeader toggle={escalationtoggle}>
                          HR COMMENTS
                        </ModalHeader>
                        <ModalBody>
                          <Row className="mb-3">
                            <Col>
                              {" "}
                              <label>
                                Reason For Escalation :
                                <Input
                                  name="text"
                                  type="textarea"
                                  style={{ width: "100%", resize: "none" }}
                                  value={hrcomments?.reason}
                                  rows="1"
                                  readOnly
                                ></Input>
                              </label>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <label>
                                Employee Comments :
                                <Input
                                  name="text"
                                  type="textarea"
                                  style={{ width: "100%", resize: "none" }}
                                  value={hrcomments?.comments}
                                  readOnly
                                ></Input>
                              </label>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col>
                              <label>
                                Hr Comments :
                                <Input
                                  name="text"
                                  type="textarea"
                                  style={{ width: "100%", resize: "none" }}
                                  value={hrcomments?.l2ManagerComments?.slice(
                                    1,
                                    hrcomments?.l2ManagerComments?.length - 1
                                  )}
                                  readOnly
                                ></Input>
                              </label>
                              {/* <textarea onChange={(e)=>setComment(e.target.value)} placeholder='Comments'  rows="4" cols="50" /> */}
                            </Col>
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={escalationtoggle}>
                            Ok
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                  ) : showEscalation === "Closed" ? (
                    <div className="form_buttons d-flex pb-3 px-4 justify-content-start gap-3 py-3">
                      <label
                        className="pr-2"
                        style={{
                          alignSelf: "center",
                          fontWeight: 500,
                          width: "12%",
                        }}
                      >
                        Appraisal Status :
                      </label>
                      <Input
                        width="70%"
                        style={{
                          color: "green",
                          backgroundColor: "rgba(235,242,252,255)",
                          border: "none",
                        }}
                        rows="1"
                        value="Your Appraisal has been Closed Successfully"
                        readOnly
                      ></Input>
                    </div>
                  ) : null}
                </div>
              </div>
            )  : (
              <div>
                <div className="col-md-12 appraisal_table">
                  <table className="table self_assessment_table">
                    <thead>
                      <tr>
                        <th className="table_heading">Dimension</th>
                        <th className="table_heading">Goals</th>
                        <th className="table_heading">Employee Comments</th>
                        <th className="table_heading">Rating</th>
                        <th className="table_heading">Manager Comments</th>
                        <th className="table_heading">Rating</th>
                        {/* <th className="table_heading">Additional Comments</th> */}
                        {/* <th className="table_heading" style={{ width: "7%" }}>
                          Weightage
                        </th> */}
                        <th className="table_heading">Attach Document</th>
                        <th className="table_heading">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table_body">
                 
                      {questionsArray?.map((questionItem, index) => (
                        <tr
                          key={
                            appraisalData?.status !== "Initialized"
                              ? questionItem.questionId
                              : questionItem.id
                          }
                        >
                          <td>
                            <Input
                              // id="exampleText"
                              disabled="true"
                              name="dimension"
                              type="text"
                              value={questionItem?.dimension=="work"?"Work(Apr2023-Dec2023)":questionItem?.dimension}
                              // value={appraisalData?.status === "Initialized" ? goalDimensionValue : questionItem.dimension}
                              onChange={(event) =>
                                handleSelectChange(event, index, "dimension")
                              }
                            ></Input>
                          </td>
                          <td>
                            <Input
                              // id="exampleText"
                              name="text"
                              type="textarea"
                              value={questionItem?.question}
                              onChange={(event) =>
                                handleSelectChange(event, index, "question")
                              }
                              rows={4}
                              // disabled={index>=len?false:true}
                            ></Input>
                          </td>
                          <td>
                            <Input
                              // id="exampleText"
                              name="employeeComments"
                              type="textarea"
                              value={questionItem?.employeeComments}
                              // onChange={(event) => handleEmployeeTextAreaChange(event, index)}
                              onChange={(event) =>
                                handleSelectChange(
                                  event,
                                  index,
                                  "employeeComments"
                                )
                              }
                              rows={4}
                            ></Input>
                          </td>
                          <td>
                            <select
                              className="rating_filter"
                              value={questionItem?.employeeRating}
                              // onChange={(event) => handleEmployeeSelectChange(event, index)}

                              onChange={(event) =>
                                handleSelectChange(
                                  event,
                                  index,
                                  "employeeRating"
                                )
                              }
                            >
                              {" "}
                              <option value="">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </td>
                          <td>
                            <Input
                              // id="exampleText"
                              name="managerComments"
                              type="textarea"
                              value={questionItem?.managerComments}
                              onChange={(event) =>
                                handleSelectChange(
                                  event,
                                  index,
                                  "managerComments"
                                )
                              }
                              rows={4}
                              disabled
                            ></Input>
                          </td>
                          <td>
                            <select
                              className="rating_filter"
                              value={questionItem?.managerRating}
                              onChange={(event) =>
                                handleSelectChange(
                                  event,
                                  index,
                                  "managerRating"
                                )
                              }
                              disabled
                            >
                              <option value="">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </td>
                          {/* <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ width: "48%" }}>
                                <label>Employee Additional Comments:</label>
                                <Input
                                  name="text"
                                  type="textarea"
                                  value={
                                    questionItem?.additionalComments?.employee
                                  }
                                  onChange={(event) =>
                                    handleSelectChange(
                                      event,
                                      index,
                                      "additionalComments",
                                      "employee"
                                    )
                                  }
                                  rows={4}
                                  disabled={disabled}
                                />
                              </div>

                              <div style={{ width: "48%" }}>
                                <label>Manager Additional Comments:</label>
                                <Input
                                  disabled
                                  name="text"
                                  type="textarea"
                                  value={
                                    questionItem?.additionalComments?.manager
                                  }
                                  onChange={(event) =>
                                    handleSelectChange(
                                      event,
                                      index,
                                      "additionalComments",
                                      "manager"
                                    )
                                  }
                                  rows={4}
                                />
                              </div>
                            </div>
                          </td> */}
                          {/* <td>
                            <Input
                              // id="exampleText"
                              disabled="true"
                              name="weightage"
                              type="number"
                              value={questionItem?.weightage}
                              // onChange={(event) =>
                              //   handleSelectChange(event, index, "weightage")
                              // }
                              // disabled
                            ></Input>
                          </td> */}
                          <td>
                            <div className="attach_docs_div">
                              <div>
                                <label
                                  htmlFor={`fileUpload-${
                                    appraisalData?.status !== "Initialized"
                                      ? questionItem.questionId
                                      : questionItem.id
                                  }`}
                                  className="btn btn-outline-secondary"
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "10px",
                                  }}
                                >
                                  <i className="bi bi-paperclip"></i>
                                </label>
                                <Input
                                  type="file"
                                  id={`fileUpload-${
                                    appraisalData?.status !== "Initialized"
                                      ? questionItem.questionId
                                      : questionItem.id
                                  }`}
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    handleFileChange(event, index)
                                  }
                                  className="form-control"
                                />
                              </div>
                              <div>
                                {/* {selectedFile[index] && <span>{selectedFile[index].name}</span>} */}
                                {questionItem.filePath && (
                                  <span className="py-2">
                                    {questionItem.filePath}
                                  </span>
                                )}
                                {questionItem.filePath && (
                                  <div className="clear_doc_div">
                                    <i
                                      className="bi bi-x-circle-fill clear_doc_icon "
                                      onClick={() =>
                                        handleClearFile(
                                          questionItem.filePath,
                                          index
                                        )
                                      }
                                    ></i>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <i
                              className={
                                // index === 0 ? 
                                // `bi bi-trash3 trash_icon dis` :
                                `bi bi-trash3 trash_icon `
                              }
                              // className={`bi bi-trash3 trash_icon ${index + 1 > len ? "" : " dis"}`}
                              onClick={() =>
                                handleDeleteRow(index, questionItem.questionId)
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div
                    className="form_buttons p-3 px-4"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      disabled={questionsArray.length===0 || disabled}
                      className="fw-semibold mx-2"
                      id="edit_save_btn"
                      color="secondary"
                      onClick={(handleEdit, handleSave)}
                    >
                      Save
                    </Button>
                    {/* <Button className='mx-2 fw-semibold' color='link'>Save as Draft</Button> */}
                    <Button
                      className="fw-semibold"
                      color="success"
                      onClick={handleSubmit}
                      disabled={questionsArray.length===0 || disabled}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <Toast
              setSuccessToast={setSuccessToast}
              setErrorToast={setErrorToast}
            />
          </div>
        </div>
      ) 
    }
    </>
  );
}

export default SelfAssessment;
