import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import '../CSS/Template.css'

// import { Button } from "reactstrap";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { addUser } from "../Store/reducers/loginReducer";
import Profile from "./Profile";

import "../CSS/topbar.css";

const Topbar = ({ toggleSidebar, direction, ...args }) => {
    let select = useSelector((state) => state?.login?.tasks);
    // console.log(select);
    let data = select[0];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);


    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };
    const Logout = () => {
        dispatch(addUser({}));
        navigate("/");
    };

    return (
        <nav>
            <div className="navbar-wrap">
                <div className="navbar-left">
                    <div>
                        <a
                            href="#"
                            color="info"
                            className="control-icon"
                            onClick={toggleSidebar}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%"
                                height="100%"
                                viewBox="0 0 24 24"
                                class="eva eva-menu-2-outline"
                                fill="currentColor">
                                <g data-name="Layer 2">
                                    <g data-name="menu-2">
                                        <rect
                                            width="24"
                                            height="24"
                                            transform="rotate(180 12 12)"
                                            opacity="0"></rect>
                                        <circle cx="4" cy="12" r="1"></circle>
                                        <rect
                                            x="7"
                                            y="11"
                                            width="14"
                                            height="2"
                                            rx=".94"
                                            ry=".94"></rect>
                                        <rect
                                            x="3"
                                            y="16"
                                            width="18"
                                            height="2"
                                            rx=".94"
                                            ry=".94"></rect>
                                        <rect
                                            x="3"
                                            y="6"
                                            width="18"
                                            height="2"
                                            rx=".94"
                                            ry=".94"></rect>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div>
                        <a href="#" className="logo">
                            Performance Management System
                        </a>
                    </div>
                </div>
                {/* <div className="navbar-center d-none d-lg-block">
                    <div className="search_div">
                        <input
                            type="search"
                            placeholder="Search"
                            id="search"
                            value={searchValue}
                            onChange={handleSearchChange}
                        />
                        {searchValue === "" && <i className="bi bi-search"></i>}
                    </div>
                </div> */}
                <div className="navbar-right">
                    <div class="user-container">
                        <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle}
                            direction={direction}>
                            <DropdownToggle
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                tag="div">
                                <div class="user-picture image">
                                    {!data.imageUrl ? (<svg
                                        width="50"
                                        height="50"
                                        viewBox="0 0 50 50"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0" y="0" width="50" height="50" fill="#FE4D93" />
                                        <text
                                            x="40%"
                                            y="40%"
                                            dominant-baseline="middle"
                                            text-anchor="middle"
                                            fill="white">
                                            {data.name
                                                .split(" ")
                                                .map((part) => part.charAt(0))
                                                .join("")}
                                        </text>
                                    </svg>) : (<img src={data.imageUrl} height="100%" width="100%" alt="" />)}

                                </div>
                                <div class="info-container d-none d-lg-block">
                                    <div class="user-name">
                                        {data.name}
                                    </div>
                                    <div class="user-title">
                                        {data.empRoleName}
                                    </div>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu {...args}>
                                <DropdownItem onClick={() => { navigate('/profile') }}>Profile</DropdownItem>
                                <DropdownItem onClick={Logout}>Log Out</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Topbar;