import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table
} from "reactstrap";
import { getReq, postReq } from "../Api/api";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router";
import '../CSS/Teams.css'
const url = process.env.REACT_APP_URL;

const Teams = () => {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [currentpage, setCurrentpage] = useState(0);
  const [lastpage, setLastpage] = useState(0);
  const itemsPerPage = 10; // Change this according to your preference

//*************************************************************************************FETCHING FILTERING OPTIONS******************************************************************* */
  const [clients, setClients] = useState([]);
  const [managers, setManagers] = useState([]);
  const [projects, setProjects] = useState([]);

  const fetchDistinct = async () => {
    try {
      const res = await getReq(`${url}employee/distinct`);
      setClients(res.data.clients);
      setManagers(res.data.managers);
      setProjects(res.data.projects);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDistinct();
  }, []);

  // Filtering
  const [params, setParams] = useState({
    clients: [],
    projects: [],
    managers: []
  });

  const handleMultiSelectChange = (selectedOptions, type) => {
    setParams((prevParams) => ({
      ...prevParams,
      [type]: selectedOptions.map((option) => option.value)
    }));
  };

  const filteredFetch = async () => {
    try {
      const res = await postReq(`${url}employee/filter`, params);
      // console.log(res.data)
      setEmployees(res.data);
      // Calculate last page
      setLastpage(Math.ceil(res.data.length / itemsPerPage)-1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    filteredFetch();
  }, [params]);

  // JSX
  return (
    <Container className="teams-container" xs="3" md="8">
      <Row className="mt-4">
        <Col>
          <h2>Employee List</h2>
        </Col>
      </Row>
      <Row className="mt-2 align-items-center">
        <Container>
          <Row>
            <Col sm={3} xs={3}>
              {managers && (
                <>
                  {/* <label htmlFor="managers">Filter by Managers</label> */}
                  <MultiSelect
                    id="managers"
                    options={managers.map((manager) => ({
                      label: manager,
                      value: manager
                    }))}
                    value={params.managers.map((manager) => ({
                      label: manager,
                      value: manager
                    }))}
                    onChange={(selectedOptions) =>
                      handleMultiSelectChange(selectedOptions, "managers")
                    }
                    labelledBy="Filter by manager"
                    overrideStrings={{ selectSomeItems: "Filter by manager" }}
                  />
                </>
              )}
            </Col>
            {/* <Col sm={3} xs={3}>
              {clients && (
                <>
                  <label htmlFor="clients">Filter by clients</label>
                  <MultiSelect
                    id="clients"
                    options={clients.map((client) => ({
                      label: client,
                      value: client
                    }))}
                    value={params.clients.map((client) => ({
                      label: client,
                      value: client
                    }))}
                    onChange={(selectedOptions) =>
                      handleMultiSelectChange(selectedOptions, "clients")
                    }
                    labelledBy="Filter by Client"
                  />
                </>
              )}
            </Col>
            <Col sm={3} xs={3}>
              {projects && (
                <>
                  <label htmlFor="projects">Filter by Projects</label>
                  <MultiSelect
                    id="projects"
                    options={projects.map((project) => ({
                      label: project,
                      value: project
                    }))}
                    value={params.projects.map((project) => ({
                      label: project,
                      value: project
                    }))}
                    onChange={(selectedOptions) =>
                      handleMultiSelectChange(selectedOptions, "projects")
                    }
                    labelledBy="Filter by project"
                  />
                </>
              )}
            </Col> */}
            <Col sm={3} xs={3}>
              <Button
                className="button"
            
                onClick={() => {
                  setParams({ clients: [], projects: [], managers: [] });
                }}
              >
                Remove filters
              </Button>
            </Col>
          </Row>
        </Container>
      </Row>
      {!employees[0]?.hasOwnProperty("message") ? (
          <>
          <Row className="mt-4 justify-content-between">
          <Table striped hover responsive className="team_table">
            <thead>
              <tr>
                <th style={{ color: "black" }}>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Reporting Manager</th>
                <th>L2 Manager</th>
                {/* <th>Client</th>
                <th>Project</th> */}
              </tr>
            </thead>
            <tbody>
              {employees &&
                employees
                  .slice(
                    currentpage *  itemsPerPage,
                    (currentpage + 1) *  itemsPerPage
                  )
                  .map((employee) => (
                    <tr
                      key={employee.id}
                      // onClick={() =>
                      //   navigate("/my-appraisal-history", { state: employee })
                      // }
                    >
                      <td scope="row">{employee.id}</td>
                      <td>{employee.name}</td>
                      <td>{employee.email}</td>
                      <td>{employee.ReportingManager}</td>
                      <td>{employee.L2_Manager}</td>
                      {/* <td>{employee.client}</td>
                      <td>{employee.project}</td> */}
                    </tr>
                  ))}
            </tbody>
          </Table>
          </Row>
          <Row>
          <Col>
            <Pagination style={{ justifyContent: "space-between" }}>
              <PaginationItem disabled={currentpage === 0}>
                <PaginationLink
                  previous
                  onClick={() => setCurrentpage((prev) => prev - 1)}
                >
                  Previous
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={currentpage === lastpage}>
                <PaginationLink
                  next
                  onClick={() => setCurrentpage((prev) => prev + 1)}
                >
                  Next
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
        </>
        ) : (
          <Table className="mt-4">
            <tbody>
            <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#f2f2f2",
                      borderRadius: "5px",
                    }}
                  >
                    <h4>{employees[0].message}</h4>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      
    </Container>
  );
};

export default Teams;
