import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CSS/EmployeeDetails.css";
import { MdMailOutline } from "react-icons/md";
import { HiOutlinePhone } from "react-icons/hi";
import { postReq, putReq } from "../Api/api";
import { Input } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Toast, { handleErrorToast, handleSuccessToast } from "./Toast";
import StepIndicator from "./StepIndicator";
 
let url = process.env.REACT_APP_URL;
const EmployeeDetails = (props) => {
  const [hrcomments, setHrComments] = useState("");
  const navigate = useNavigate()
 const prop1 =props.prop1[0];
 const prop2= props.prop1[1]
//  console.log(prop1 ,prop2)
  const [details] = useState({
    name: "Judith Kathryn",
    id: "0850",
    email: "Judithkathryn@gmail.com",
    pos: "Full-time",
    dept: "IT-Developer",
    tel: "(704) 555-0127",
  });
  const HrCommentssubmit = () => {
    if (!hrcomments?.trim()) {
      handleErrorToast("Please Fill The Comments");
      return;
    }
    // console.log(hrcomments)
    postReq(
      `${url}escalateMaster/addHrComment/${prop2?.appraisal_master_id}`,
      hrcomments
    ).then((res) => {
      if (res.status != 200) {
      
        handleErrorToast("Error in Submission of the Comments");
      } else {
        putReq(
          `${url}api/sa/status/${prop2?.appraisal_master_id}/HRSubmitted`
        ).then((data) => {
          if (data.status != 200) {
            handleErrorToast(
              "Comments submitted successfully error in changing state "
            );
           
          } else {
            handleSuccessToast("Successfully Submitted");
            navigate("/escalation")
            putReq(
              `${url}escalateMaster/statusUpdate/${prop2?.appraisal_master_id}/Closed`
            );
           
          }
        });
      }
    });

    setHrComments("");
  };
  // console.log(prop2?.l2_manager_comments);

  return (
    <>

    <div className="component-container" draggable>
    
      <div className="employee-detail-container">
        <Toast></Toast>
        <div className="heading">Employee Details</div>
        <div className="details">
          <div>
            Employee Name:{" "}
            <span style={{ fontWeight: 600 }}>{prop1?.name}</span>
          </div>
          <div>
            ID: <span style={{ fontWeight: 600 }}>{prop1?.employeeId}</span>
          </div>
          <div className="accent-color bold center">
            <MdMailOutline size={25} /> {prop2?.email}
          </div>
          <div>
            Position:{" "}
            <span style={{ fontWeight: 600 }}>{prop1?.bussinessunit}</span>
          </div>
          <div>
            Department:{" "}
            <span style={{ fontWeight: 600 }}>{prop1?.department}</span>
          </div>
          <div className="accent-color bold center">
            <HiOutlinePhone size={25} /> {prop2?.contactNumber}
          </div>
        </div>
      </div>
      <div className="esc-details-container">
        <div className="heading esc-heading">
          <span>Escalation Details</span>
        </div>
        <div className="esc-details">
          <div className="esc-details-item custom-select">
            <label htmlFor="esc-reason" style={{ width: "90px" }}>
              Reason for Escalation
            </label>
            <Input
              type="text"
              name="esc-reason"
              id="esc-reason"
              value={prop2?.reason}
              readOnly
            ></Input>
          </div>
          <div className="esc-details-item">
            <label htmlFor="esc-init">Initiated by</label>
            <Input
              name="esc-init"
              id="esc-init"
              value={prop2?.initiated_by}
              readOnly
            ></Input>
          </div>
        </div>
        <div className="d-flex pt-2 pb-4" style={{ gap: "65px" }}>
          <div className="d-flex" style={{ width: "50%", gap: "20px" }}>
            <span style={{ alignSelf: "center" }}>Comment</span>
            <Input
              type="textarea"
              idcols="30"
              rows="5"
              value={prop2?.comments}
              readOnly
            ></Input>
          </div>
          <div className="d-flex" style={{ width: "50%" }}>
            <span style={{ alignSelf: "center" }}>HR Comments</span>
            {prop2?.l2_manager_comments && (
              <Input
                type="textarea"
                id="hr-comments"
                // cols="30"
                rows="7"
                value={prop2?.l2_manager_comments?.slice(1,prop2.l2_manager_comments?.length-1)}
                readOnly
              />
              
            )}
            {!prop2?.l2_manager_comments && (
              <Input
                type="textarea"
                id="hr-comments"
                // cols="30"
                rows="5"
                placeholder="Enter Your views"
                onChange={(e) => setHrComments(e.target.value)}
              />
            
            )}
          </div>
        </div>
        {!prop2?.l2_manager_comments && (
          <div className="esc-cta-buttons py-3">
            <button onClick={HrCommentssubmit}>Save</button>
            <button>Cancel</button>
          </div>
        )}
      </div>
    </div>
    </> );
};

export default EmployeeDetails;
