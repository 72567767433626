import React, { useState } from "react";
import { Container, Row, Col, Table, Button } from "reactstrap";
import "../CSS/EmployeeStatus.css";

const tableDummyData = [
  {
    managerName: "Gladys",
    status: "Yet to submit",
    dueDate: "07-11-19",
    project: "ABC",
    details: "Details",
  },
  {
    managerName: "Gladys",
    status: "Submitted",
    dueDate: "07-11-19",
    project: "ABC",
    details: "Details",
  },
  {
    managerName: "Gladys",
    status: "Yet to submit",
    dueDate: "07-11-19",
    project: "ABC",
    details: "Details",
  },
  {
    managerName: "Gladys",
    status: "Yet to submit",
    dueDate: "07-11-19",
    project: "ABC",
    details: "Details",
  },
  {
    managerName: "Gladys",
    status: "Yet to submit",
    dueDate: "07-11-19",
    project: "ABC",
    details: "Details",
  },
  {
    managerName: "Gladys",
    status: "Submitted",
    dueDate: "07-11-19",
    project: "ABC",
    details: "Details",
  },
];

const countYetToSubmit = tableDummyData.filter(
  (row) => row.status === "Yet to submit"
).length;
const countSubmitted = tableDummyData.filter(
  (row) => row.status === "Submitted"
).length;

const EmployeeStatus = () => {
  const [tableData, setTableData] = useState(tableDummyData);
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = () => {
    // console.log("click")
    const sortedData = [...tableData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.status.localeCompare(b.status);
      } else {
        return b.status.localeCompare(a.status);
      }
    });

    setTableData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  return (
    <>
      <h3 className="textNow pt-3">
        Employee Summary
      </h3>
      <div className="hero d-flex align-items-center">
        <Container>
          <Row className="completeStatus">
            <Col className="statusText" xs="4">
              <i className="bi bi-check-circle text-success"></i>{" "}
              {countSubmitted} Completed
            </Col>
            <Col className="statusText" xs="4">
              <i className="bi bi-x-circle text-danger"></i> {countYetToSubmit}{" "}
              Incomplete
            </Col>
            <Col className="statusText" xs="4">
              <i className="bi bi-arrow-repeat text-warning"></i> 0 In-Progress
            </Col>
          </Row>
        </Container>
      </div>
      <h3 className="textNow pt-3">
        Employee Status
      </h3>
      <div style={{ overflowX: "auto", margin: "15px" }}>
        <Table borderless className="tableHere">
          <thead className="tableHeader" style={{ backgroundColor: "#f0f0f0" }}>
            <tr>
              <th>Manager Name</th>
              <th onClick={handleSort}>
                Status
              </th>
              <th>Due Date</th>
              <th>Project</th>
              <th className="table-header-40"></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.managerName}</td>
                <td>
                  {" "}
                  <div
                    className={`statusCircle ${row.status === "Yet to submit" ? "colorOrange" : "colorGreen"}`}
                  ></div>
                  {row.status}
                </td>
                <td>{row.dueDate}</td>
                <td>{row.project}</td>
                <td>
                  <Button style={{ float: "right" }} className="buttonDetail">
                    {row.details}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default EmployeeStatus;
