import React from 'react';
import { Container, Table, CardTitle, Row, Col, Card, CardBody, Button } from 'reactstrap';
import '../CSS/Reports.css';
import Timeseries from './Timeseries';
import Piechart from './Piechart';


const Reports = () => {
  const timeSeriesData = [
    {
      x: new Date('2024-02-01T00:00:00'),
      y: 30,
    },
    {
      x: new Date('2024-02-02T00:00:00'),
      y: 45,
    },
  ];
  const pieChartData = [30, 50, 20];
  const labels=["Open","Closed","Escalated"]
  return (
    <div className='reports_container' id="base">
      <Container>
        <Row className="mt-3">
          <Col md={6} className="mb-3">
            <Card id='chart-card'>
              <CardBody>
                <CardTitle tag="h4">
                  Yearly Performance Trends
                </CardTitle>
                <center style={{padding:'10px',marginTop:'6%'}}>
              
                    <Timeseries data={timeSeriesData} />
      
                </center>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} className="mb-3">
            <Card  id='chart-card'>
              <CardBody>
                <CardTitle tag="h4">
                  Appraisal Status
                </CardTitle>
                <center style={{padding:'10px'}}>
                  <Piechart data={pieChartData} lab={labels}/>
                </center>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6} className="mb-3">
            <Card  id='chart-card'>
              <CardBody>
                <CardTitle tag="h4">
                  Low Performers of December
                  <Button style={{float:'right'}}>View all</Button>
                </CardTitle>
                <hr></hr>
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>
                        First Name
                      </th>
                      <th>
                        Last Name
                      </th>
                      <th>
                        Username
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        1
                      </th>
                      <td>
                        Mark
                      </td>
                      <td>
                        Otto
                      </td>
                      <td>
                        @mdo
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        2
                      </th>
                      <td>
                        Jacob
                      </td>
                      <td>
                        Thornton
                      </td>
                      <td>
                        @fat
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        3
                      </th>
                      <td>
                        Larry
                      </td>
                      <td>
                        the Bird
                      </td>
                      <td>
                        @twitter
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        4
                      </th>
                      <td>
                        Marks
                      </td>
                      <td>
                        Ottova
                      </td>
                      <td>
                        @mdoa
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        5
                      </th>
                      <td>
                        Marker
                      </td>
                      <td>
                        Ottovi
                      </td>
                      <td>
                        @mdoab
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} className="mb-3">
            <Card  id='chart-card'>
              <CardBody>
                <CardTitle tag="h4">
                  Top Performers of December
                  <Button style={{float:'right'}}>View all</Button>
                </CardTitle>
                <hr></hr>
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>
                        First Name
                      </th>
                      <th>
                        Last Name
                      </th>
                      <th>
                        Username
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        1
                      </th>
                      <td>
                        Mark
                      </td>
                      <td>
                        Otto
                      </td>
                      <td>
                        @mdo
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        2
                      </th>
                      <td>
                        Jacob
                      </td>
                      <td>
                        Thornton
                      </td>
                      <td>
                        @fat
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        3
                      </th>
                      <td>
                        Larry
                      </td>
                      <td>
                        the Bird
                      </td>
                      <td>
                        @twitter
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        4
                      </th>
                      <td>
                        Marks
                      </td>
                      <td>
                        Ottova
                      </td>
                      <td>
                        @mdoa
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        5
                      </th>
                      <td>
                        Marker
                      </td>
                      <td>
                        Ottovi
                      </td>
                      <td>
                        @mdoab
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={12} className="mb-3">
            <Card  id='chart-card'>
              <CardBody>
                <CardTitle tag="h4">
                  Recommended Skills
                  <Button style={{float:'right'}}>View all</Button>
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>
                        Employee Name
                      </th>
                      <th>
                        Role
                      </th>
                      <th>
                        Department
                      </th>
                      <th>
                        Required Skills
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        1
                      </th>
                      <td>
                        Mark
                      </td>
                      <td>
                        Otto
                      </td>
                      <td>
                        @mdo
                      </td>
                      <td>
                        <Button className='skill-button'>
                          Selenium
                        </Button>
                        <Button className='skill-button'>
                          Automation
                        </Button>
                        <Button className='skill-button'>
                          Firebug
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        2
                      </th>
                      <td>
                        Jacob
                      </td>
                      <td>
                        Thornton
                      </td>
                      <td>
                        @fat
                      </td>
                      <td>
                        <button className='skill-button'>
                          Selenium
                        </button>
                        <button className='skill-button'>
                          Automation
                        </button>
                        <button className='skill-button'>
                          Firbug
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        3
                      </th>
                      <td>
                        Larry
                      </td>
                      <td>
                        the Bird
                      </td>
                      <td>
                        @twitter
                      </td>
                      <td>
                        <button className='skill-button'>
                          Selenium
                        </button>
                        <button className='skill-button'>
                          Automation
                        </button>
                        <button className='skill-button'>
                          Firbug
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Reports;
