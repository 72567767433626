import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import login from "./reducers/loginReducer";
import { persistStore, persistReducer,FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
const persistConfig = {
  key: 'root',
  storage,
  // Specify the reducers you want to persist
  whitelist: ['login'], // In this example, we persist the 'user' reducer
};
const reducercomb = combineReducers({login})
const persistedReducer = persistReducer(persistConfig, reducercomb);
export const store = configureStore({reducer:persistedReducer,
  middleware: (getDefaultMiddleware) =>
getDefaultMiddleware({
  serializableCheck:{
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
}),});
export const persistor = persistStore(store);