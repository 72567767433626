import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Input,
  Placeholder,
} from "reactstrap";
import { getReq, postReq } from "../Api/api";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import "../CSS/MyTeam.css";
const url = process.env.REACT_APP_URL;
const MyTeam = () => {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const select = useSelector((state) => state?.login?.tasks);
  const id = select[0].id;
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState()
  const [params, setParams] = useState({
    clients: [],
    projects: [],
  });
  const [currentpage, setCurrentpage] = useState(0);
  const [lastpage, setLastpage] = useState(0);
  const entriesperpage = 10;

  // const [empstatus, setEmpstatus] = useState();
  // const [status, setStatus] = useState();
  // const empStatus = async () => {
  //   try {
  //     const res = await getReq(`${url}getemployeestatus`);
  //     console.log(res.data);
  //     setEmpstatus(Object.keys(res.data));

  //     setStatus(Object.values(res.data));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    fetchDistinct();
  }, []);

  const fetchDistinct = async () => {
    try {
      const res = await getReq(`${url}employee/distinct`);
      setClients(res.data.clients);
      setProjects(res.data.projects);
    } catch (error) {
      console.error("Error fetching distinct values:", error);
    }
  };

  const Fetch = async () => {
    try {
      const res = await postReq(`${url}employee/getByManager`, {
        manager: id,
        clients: params.clients,
        projects: params.projects,
      });
      // console.log("RREs", res);
      setEmployees(res.data);
      // console.log("LEN".res.data.length);
      setLastpage(Math.ceil(res.data.length / entriesperpage) - 1);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  useEffect(() => {
    Fetch();
    // empStatus();
  }, [params, currentpage]); // Trigger fetch when parameters or page change

  const handleMultiSelectChange = (selectedOptions, type) => {
    setParams((prevParams) => ({
      ...prevParams,
      [type]: selectedOptions.map((option) => option.value),
    }));
  };

  return (
    <Container className="teams-container" xs="3" md="8">
      {employees[0]?.message == "No employees are currently reporting to you" ? <Table className="mt-4">
        <tbody>
          <tr>
            <td colSpan="7" style={{ textAlign: "center" }}>
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#f2f2f2",
                  borderRadius: "5px",
                }}
              >
                <h4>{employees[0].message}</h4>
              </div>
            </td>
          </tr>
        </tbody>
      </Table> : <>
        <Row className="mt-4">
          <Col>
            <h2>Employee List</h2>
          </Col>
        </Row>
        {/* <Row className="mt-2 align-items-center">
        <Container>
          <Row>
            <Col sm={4} xs={4}>
              {clients && (
                <>
                  <label htmlFor="clients">Filter by clients</label>
                  <MultiSelect
                    id="clients"
                    options={clients.map((client) => ({
                      label: client,
                      value: client,
                    }))}
                    value={params.clients.map((client) => ({
                      label: client,
                      value: client,
                    }))}
                    onChange={(selectedOptions) =>
                      handleMultiSelectChange(selectedOptions, "clients")
                    }
                    labelledBy="Filter by Client"
                  />
                </>
              )}
            </Col>
            <Col sm={4} xs={4}>
              {projects && (
                <>
                  <label htmlFor="projects">Filter by Projects</label>
                  <MultiSelect
                    id="projects"
                    options={projects.map((project) => ({
                      label: project,
                      value: project,
                    }))}
                    value={params.projects.map((project) => ({
                      label: project,
                      value: project,
                    }))}
                    onChange={(selectedOptions) =>
                      handleMultiSelectChange(selectedOptions, "projects")
                    }
                    labelledBy="Filter by project"
                  />
                </>
              )}
            </Col>
            <Col sm={4} xs={4}>
              <Button
                className="mt-4"
                color="primary"
                onClick={() => {
                  setParams({ clients: [], projects: [], managers: [] });
                }}
              >
                Remove filters
              </Button>
            </Col>
          </Row>
        </Container>
      </Row> */}

        {!employees[0]?.hasOwnProperty("message") ? (
          <>
            <Row className="mt-4 justify-content-between">
             <Row className="mb-3">
             <div className="col-3">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  outline
                  className="searchbox"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {/* <FontAwesomeIcon icon={faSearch} className="search-icon" /> */}
              </div>
             </Row>
              <Table striped hover responsive className="team_table">
                <thead>
                  <tr>
                    <th style={{ color: "black" }}>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Appraisal Status</th>
                    <th>L2 Manager</th>

                    {/* <th>Status</th> */}
                    {/* <th>Client</th>
                <th>Project</th> */}
                  </tr>
                </thead>
                <tbody>
                  {employees &&
                    employees
                      .filter((item) =>

                     {
                      if(searchTerm){
                        return item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
                      }
                      else return true
                     }
                      )
                      .slice(
                        currentpage * entriesperpage,
                        (currentpage + 1) * entriesperpage
                      )
                      .map((employee) => (
                        <tr
                          key={employee.id}
                          onClick={() => {
                            navigate("/my-appraisal-history", { state: employee })
                          }}
                        >
                          <td scope="row">{employee.id}</td>
                          <td>{employee.name}</td>
                          <td>{employee.email}</td>
                          <td>{employee.status}</td>
                          <td>{employee.L2_Manager}</td>
                          {/* <td>{employee.client}</td>
                      <td>{employee.project}</td> */}
                        </tr>
                      ))}
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col>
                <Pagination style={{ justifyContent: "space-between" }}>
                  <PaginationItem disabled={currentpage === 0}>
                    <PaginationLink
                      previous
                      onClick={() => setCurrentpage((prev) => prev - 1)}
                    >
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem disabled={currentpage === lastpage}>
                    <PaginationLink
                      next
                      onClick={() => setCurrentpage((prev) => prev + 1)}
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </>
        ) : (
          <Table className="mt-4">
            <tbody>
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#f2f2f2",
                      borderRadius: "5px",
                    }}
                  >
                    <h4>{employees[0].message}</h4>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        )}</>}




    </Container>
  );
};

export default MyTeam;
