import React from 'react';
import { Container, Table, CardTitle, Row, Col, Card, CardBody, Button } from 'reactstrap';
import Timeseries from './Timeseries';
import "../CSS/myreports.css";


const MyReports = () => {
  const timeSeriesData = [
    {
      x: new Date('2024-02-01T00:00:00'),
      y: 30,
    },
    {
      x: new Date('2024-02-02T00:00:00'),
      y: 45,
    },
    // Add more data points as needed
  ];
  return (
    <div className='reports_container' id="base">
      <Container>
        {/* Top Row */}
        <h3 style={{padding:'2% 0 2% ',color:'green'}}>
          My Performance Ranking For The December Month Is # 6
        </h3>
        <Row className="mt-3">
          {/* First Card */}
          <Col md={6} className="mb-3">
            <Card id='chart-card'>
              <CardBody>
                <CardTitle tag="h4">
                 My Performance Trends
                </CardTitle>
                <center style={{padding:'10px',marginTop:'6%'}}>
              
                    <Timeseries data={timeSeriesData} />
      
                </center>
              </CardBody>
            </Card>
          </Col>

          {/* Second Card */}
          <Col md={6} className="mb-3">
            <Card  id='chart-card'>
              <CardBody>
                <CardTitle tag="h4">
                 My Appraisal Status
                </CardTitle>
                <center style={{padding:'10px'}}>
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>
                        E-Name
                      </th>
                      <th>
                        Appraisal Master Id
                      </th>
                      <th>
                        Appraisal Issue Date
                      </th>
                      <th>
                        Appraisal Settled Date
                      </th>
                      <th>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        1
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                        <center>1</center>
                      </td>
                      <td>
                        11-12-2023
                      </td>
                      <td>
                        25-12-2023
                      </td>
                      <td>
                        Completed
                      </td>
                    </tr>
                  </tbody>
                </Table>
                </center>
                <div>
                  <h3 style={{float:'left'}}>
                    Appraisal history
                  </h3>
                  <Button style={{float:'right'}}>
                    View All
                  </Button>
                </div>
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>
                        E-Name
                      </th>
                      <th>
                        Appraisal Master Id
                      </th>
                      <th>
                        Appraisal Issue Date
                      </th>
                      <th>
                        Appraisal Settled Date
                      </th>
                      <th>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                       
                      <center> 1 </center>
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                      <center> 1 </center>
                      </td>
                      <td>
                        11-12-2023
                      </td>
                      <td>
                        25-12-2023
                      </td>
                      <td>
                        Completed
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                      <center> 1 </center>
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                      <center> 1 </center>
                      </td>
                      <td>
                        11-12-2023
                      </td>
                      <td>
                        25-12-2023
                      </td>
                      <td>
                        Completed
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                      <center> 1 </center>
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                      <center> 1 </center>
                      </td>
                      <td>
                        11-12-2023
                      </td>
                      <td>
                        25-12-2023
                      </td>
                      <td>
                        Completed
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                      <center> 1 </center>
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                        <center> 1 </center>
                      </td>
                      <td>
                        11-12-2023
                      </td>
                      <td>
                        25-12-2023
                      </td>
                      <td>
                        Completed
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
        <Row className="justify-content-center">
          {/* Third Card with 100% width */}
          <Col md={12} className="mb-3">
            <Card  id='chart-card'>
              <CardBody>
                <CardTitle tag="h4">
                 My Goals History
                <Button style={{float:'right'}}>View all</Button>
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>
                        Employee Name
                      </th>
                      <th>
                        Goals
                      </th>
                      <th>
                        Achieved ratings(Out of 5)
                      </th>
                      <th>
                        Comments
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        1
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                        Learn Intermediate Level Java Backend 
                      </td>
                      <td>
                        4
                      </td>
                      <td>
                        Fine learnings
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        2
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                        Learn Intermediate Level Express
                      </td>
                      <td>
                        2
                      </td>
                      <td>
                        Need to Improve
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        3
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                        Learn React js 
                      </td>
                      <td>
                        1
                      </td>
                      <td>
                      Need to work more
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        4
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                        Learn Flutter
                      </td>
                      <td>
                        5
                      </td>
                      <td>
                        Good learnings
                      </td>
                    </tr>

                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>


        {/* Middle Row */}
        <Row className="justify-content-center">
          {/* Third Card with 100% width */}
          <Col md={12} className="mb-3">
            <Card  id='chart-card'>
              <CardBody>
                <CardTitle tag="h4">
                  Recommended Skills
                <Button style={{float:'right'}}>View all</Button>
                </CardTitle>
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th>
                        Employee Name
                      </th>
                      <th>
                        Role
                      </th>
                      <th>
                        Department
                      </th>
                      <th>
                        Suggested Skills
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        1
                      </th>
                      <td>
                        Siddhartha
                      </td>
                      <td>
                        Otto
                      </td>
                      <td>
                        @mdo
                      </td>
                      <td>
                        <Button className='skill-button'>
                          Selenium
                        </Button>
                        <Button className='skill-button'>
                          Automation
                        </Button>
                        <Button className='skill-button'>
                          Firebug
                        </Button>
                      </td>
                    </tr>

                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Bottom Row (if needed) */}
        {/* ... */}
      </Container>
    </div>
  );
}

export default MyReports;
